import { Box, styled} from "@mui/material";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(({theme})=>({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 150,
  zIndex: 1,
  [theme.breakpoints.down("sm")]: {
    gap: 100,
  },
}));

export const RightSideContainer = styled(Box)(({theme})=>({
  display: "flex",
  gap: 160,
  boxSizing: "border-box",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    gap: 50,
  },
}));

export const LeftSideContainer = styled(Box)(({theme})=>({
  display: "flex",
  alignItems: "center",
  gap: 160,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 50,
  },
}));

export const TextContainer = styled(Box)(({theme})=>({
  display: "flex",
  gap: 28,
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    textAlign: "center",
  },
}));


export const CardContainer = styled(Box)(({theme})=>({
  display: "flex",
  flexDirection: "row",
  height: 350,
  minWidth: 435,
  padding: 15,
  alignItems: 'flex-start',
  justifyContent: 'center',
  overflow: 'hidden',
  paddingBottom: 0,
  borderRadius: 25,
  background: colors.gradientBlackPurple,
  [theme.breakpoints.down("sm")]: {
    width: 'fit-content',
    minWidth: 0,
  },

}));


export const CardContainerMobile = styled(Box)(({theme})=>({
  display: "flex",
  flexDirection: "row",
  height: 350,
  minWidth: 435,
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: 15,
  overflow: 'hidden',
  paddingBottom: 0,
  borderRadius: 25,
  background: colors.gradientBlackPurple,
  [theme.breakpoints.down("sm")]: {
    width: 'fit-content',
    minWidth: 0,
   },
}));

export const VideoCard = styled("video")(({ theme }) => ({
  width: 410,
  height: 400,
  borderRadius: 25,
  marginTop: -5,
  cursor: "pointer",
  "&:hover": {
    cursor: "pointer",
    autoplay: "true",
  },
  [theme.breakpoints.down("sm")]: {
    width: '100%',
    height: '100%',
    marginBottom: '-20px',
   },

}));

export const ImgCard = styled("img")(({theme})=>({
  width: 400,
  [theme.breakpoints.down("sm")]: {
    width: '100%',
    height: '100%',
    marginBottom: '-20px',
   },
}));

export const MobileImgCard = styled("img")(({theme})=>({
  width: 277,
  height: 574,
  [theme.breakpoints.down("sm")]: {
    width: '80%',
   },
}));

export const PurpleLink = styled("a")(({theme})=>({
  textDecoration: 'none',
  cursor: 'pointer',
  fontSize: 14,
  color: colors.main,
  marginTop: "auto",
  marginBottom: 15,
}));