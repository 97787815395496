
import { BigNumberish, ethers } from "ethers";

export const padDecimals = (amount: BigNumberish, minDecimals: number) => {
    let amountStr = amount.toString();
    const dotIndex = amountStr.indexOf(".");
    if (dotIndex !== -1) {
      const decimals = amountStr.length - dotIndex - 1;
      if (decimals < minDecimals) {
        amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), "0");
      }
    } else {
      amountStr = amountStr + ".0000";
    }
    return amountStr;
  };


export const limitDecimals = (amount: BigNumberish, maxDecimals?: number) => {
    let amountStr = amount.toString();
    if (maxDecimals === undefined) {
      return amountStr;
    }
    if (maxDecimals === 0) {
      return amountStr.split(".")[0];
    }
    const dotIndex = amountStr.indexOf(".");
    if (dotIndex !== -1) {
      let decimals = amountStr.length - dotIndex - 1;
      if (decimals > maxDecimals) {
        amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals));
      }
    }
    return amountStr;
  };

  export function numberWithCommas(x: BigNumberish) {
    if (!x) {
      return "...";
    }
  
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  

export const formatAmount = (
    amount: BigNumberish | undefined,
    tokenDecimals: number,
    displayDecimals?: number,
    useCommas?: boolean,
    defaultValue?: string
  ) => {
    if (!defaultValue) {
      defaultValue = "-";
    }
    if (amount === undefined || amount.toString().length === 0) {
      return defaultValue;
    }
    if (displayDecimals === undefined) {
      displayDecimals =  3;
    }
    let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
    amountStr = limitDecimals(amountStr, displayDecimals);
    if (displayDecimals !== 0) {
      amountStr = padDecimals(amountStr, displayDecimals);
    }
    if (useCommas) {
      return numberWithCommas(amountStr);
    }
    return amountStr;
  };