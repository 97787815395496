import React from "react";
import parse from "html-react-parser";
import {
  Contents,
  ContentsTopSection,
  ContentsTopSectionText,
  ContentsTopSectionTitle,
  Description,
  MainContainer,
  NavItem,
  NavLetter,
  Navs,
  NavTitle,
  Term,
  TermParagraph,
  TermParagraphs,
  TermParagraphsNumber,
  TermParagraphsText,
  TermSubParagraph,
  TermSubParagraphsText,
  TermSubParagraphsNumber,
  TermsContainer,
  TermsSection,
  TermTitle,
  TermTitleExtra,
  Title,
} from "./styled";

const terms = [
  {
    letter: "A",
    title: "Introduction",
    paragraphs: [
      {
        paragraph: "1",
        text: "Our trading platform www.palmswap.org (the “<strong>Platform</strong>”) allows you to trade perpetual futures contracts (the “<strong>Services</strong>”), which represent obligations to buy or sell a digital asset (an “<strong>Underlying Digital Asset</strong>” means a blockchain-based asset) at a specific price, at any time while the contract remains open. perpetual futures contracts do not have an expiry date but instead, continuously roll over, i.e. every hour.  (the “<strong>Perpetual Futures Contract</strong>”). A Perpetual Futures Contract is based on a smart contract and is based on the Binance Smart Chain to which the Website’s and/or Platform API allows you to access, via your Account (as hereinafter defined) (the “<strong>Smart Contract</strong>”)",
      },
      {
        paragraph: "2",
        text: "You can trade Perpetual Futures Contracts on the Platform by supplying the applicable amount of collateral in the form offered per each Perpetual Futures Contract (the “<strong>Collateral</strong>”) to cover initial and maintenance margins. The Settlement of Perpetual Futures Contracts shall be performed in accordance with the details of each Perpetual Futures Contract.",
      },
      {
        paragraph: "3",
        text: "Perpetual Futures Contracts are complex products and the trading of Perpetual Futures Contracts is high risk. The market price of any Perpetual Futures Contracts may fluctuate significantly in response to the value of the Underlying Digital Asset’s(s’) price, supply and demand, and other market factors. Depending on market movements, your positions may be liquidated, and you may sustain a total loss of the Collateral. This is because Perpetual Futures Contract trading could also be leveraged, with a relatively small amount of funds used to establish a position in regards to an Underlying Digital Asset. For instance, a small price decrease on a 2x leveraged Perpetual Futures Contact’s Underlying Digital Asset could result in 2x loss in your leveraged position in the Futures Contract. Further, short positions will lose money when the price of the Underlying Digital Asset rises, a result that is opposite from holding the Underlying Digital Asset.",
      },
    ],
  },
  {
    letter: "B",
    title: "Establishing an Account",
    paragraphs: [
      {
        paragraph: "1",
        text: "In order to use the Platform, it is required to integrate your compatible third-party wallet, such as MetaMask (“<strong>Wallet</strong>”) with the Website, when you integrate into the Platform and transfer funds for the first time to a Smart Contract, you create an account (the “<strong>Account</strong>”). The Website may be compatible with different types of Wallets that interact with applicable Blockchains and support holding and transferring of different Collaterals. ",
      },
      {
        paragraph: "2",
        text: "Your Account would be directly linked to your Wallet. You are the sole owner of your Account and their means of access (such as, e.g., private keys), and we have no access (excluding such occasions where we ask your permissions) to such Account and have no way to assist you in an event where you lose such means of access. ",
      },
      {
        paragraph: "3",
        text: "It is your responsibility to ensure your computer or mobile device meets all the necessary technical specifications to enable you to access and use the Website and/or the Platform. Palmswap does not provide you with any physical equipment to access and/or use our Services. You are responsible for all fees charged by third parties related to your access and use of the Website and/or the Platform (e.g., gas fees and any other potential charges by internet service providers or air time charges). ",
      },
    ],
  },
  {
    letter: "C",
    title: "Use of Platform, Services and Website",
    paragraphs: [
      {
        paragraph: "1",
        text: "In order to be eligible to open an Account or use the Platform (and to enter into the ToU), you must meet (and you represent and warrant that you do meet), the following <strong>eligibility criteria</strong>:",
      },
      {
        paragraph: "1.1",
        text: "If you are an individual, you must be at least 18 years of age, have the capacity to accept the ToU, and not have been previously suspended or removed from access to the Platform or any other service or product offered by Palmswap or any of its affiliates, and are otherwise eligible to use the services of the Website and/or Platform under the laws and regulations which apply to you (the “<strong>Applicable Law</strong>”).",
      },
      {
        paragraph: "1.2",
        text: "If you are creating an Account to use the Services on behalf of a legal entity, then: (A) you must be duly authorized by such legal entity to act on its behalf for the purpose of entering into the ToU; (B) the legal entity must be duly organized and validly existing under the laws of the jurisdiction of its organization; and (C) the legal entity must not have been (and each of its affiliates must not have been) previously suspended or removed from access to the Platform and/or Website or any other service or product offered by Palmswap or any of its affiliates and must be otherwise eligible to use the Services under Applicable Law.",
      },
      {
        paragraph: "1.3",
        text: "You have not: violated; been fined, debarred, sanctioned, the subject of economic sanctions-related restrictions, or otherwise penalized under; received any oral or written notice from any government concerning actual or possible violation by you under; or received any other report that you are the subject or target of sanctions, restrictions, penalties, or enforcement action or investigation under, any Applicable Law (including but not limited to AML, CTF, anti-corruption, or economic sanctions laws).",
      },
      {
        paragraph: "1.4",
        text: `You may not access the Services, the Platform, or the Website, if you have your registered office or place of residence in the United States of America, Brazil, Cuba, Crimea and Sevastopol, Luhansk People's Republic, Donetsk People's Republic, Iran, Afghanistan, Syria, North Korea, or Antigua and Barbuda, or any other territory, as may be amended by the <a href="https://home.treasury.gov/">U.S. DOT</a> and/or any other applicable governmental authority of competent jurisdiction (each a “<strong>Restricted Territory</strong>”). We retain the right to amend the list presented herein from time to time due to changes in regulatory requirements without prior notice`,
      },
      {
        paragraph: "1.5",
        text: "You will not be using the Services for any illegal activity including, but not limited to the following:",
      },
      {
        paragraph: "1.5.1",
        text: "violate or assist any party in violating any Applicable Laws or any rule of any self-regulatory or similar organization of which you are or are required to be a member through your use of the Services;",
      },
      {
        paragraph: "1.5.2",
        text: "provide false, inaccurate, incomplete, out-of-date or misleading information;",
      },
      {
        paragraph: "1.5.3",
        text: `infringe upon Palmswap's or any third party's copyrights, patents, trademarks, or other intellectual property rights;`,
      },
      {
        paragraph: "1.5.4",
        text: "engage in any illegal activity, including without limitation illegal gambling, money laundering, fraud, blackmail, extortion, ransoming data, the financing of terrorism, other violent activities or any prohibited market practices;",
      },
      {
        paragraph: "1.5.5",
        text: "engage in any behavior which is unlawful, violates the ToU, or is otherwise deemed unacceptable by Palmswap in its sole discretion. ",
      },
      {
        paragraph: "1.6",
        text: "You may not use the Services, if:",
      },
      {
        paragraph: "1.6.1",
        text: "You or the entity you represent are current residents of a Restricted Territory. ",
      },
      {
        paragraph: "1.6.2",
        text: "You are a member of any sanctions list or equivalent maintained by the United States government, the United Kingdom government or the European Union;",
      },
      {
        paragraph: "1.6.3",
        text: "You intend to transact with any of the above mentioned;",
      },
      {
        paragraph: "1.6.4",
        text: "you are located, incorporated or otherwise established in, or a citizen or resident of a jurisdiction where it would be illegal under Applicable Law for you (by reason of your nationality, domicile, citizenship, residence or otherwise) to access or use the Services; or",
      },
      {
        paragraph: "1.6.5",
        text: "the publication or availability of the Services in the jurisdiction in which you are based is prohibited or contrary to local law or regulation or could subject Palmswap to any local registration or licensing requirements.",
      },
    ],
  },
  {
    letter: "D",
    title: "Depositing Colleteral",
    paragraphs: [
      {
        paragraph: "1",
        text: "You may deposit funds to a Smart Contract to be used as Collateral.",
      },
      {
        paragraph: "2",
        text: "By depositing funds, you interact with our applicable Smart Contract. We do not control the Smart Contract, or have access to such funds, and such funds do not remain in our custody.",
      },
      {
        paragraph: "3",
        text: "PLEASE NOTE THAT THE funds and funds that are held as a Collateral in a Perpetual Futures Contract HELD IN a Smart Contract ARE NOT ELIGIBLE FOR ANY PUBLIC OR PRIVATE DEPOSIT INSURANCE PROTECTION, AND AS SUCH, THEY ARE NOT INSURED",
      },
    ],
  },
  {
    letter: "E",
    title: "Fees",
    paragraphs: [
      {
        paragraph: "1",
        text: "With respect to using the Services, you may be required to pay third-party fees (such as, for example, gas fees) for your usage and interactions with the Binance Smart Chain network (the “Network”) where the Smart Contract is deployed. The Network is a third-party service, which is not, whatsoever, associated with Palmswap.",
      },
      {
        paragraph: "2",
        text: "We may charge fees for the usage of the Platform, Services and Website, and we reserve the right to change, alter or otherwise cancel such fees, at our sole discretion. Please note that such fees will be automatically deducted by us from your funds in the Smart Contract.",
      },
    ],
  },
  {
    letter: "F",
    title: "User representations and acknowledgment of risks",
    titleExtra:
      "By accessing the Website or using the Services, you expressly acknowledge and assume the following risks:",
    paragraphs: [
      {
        paragraph: "1",
        text: "No Financial and Legal Advice. Palmswap, including the Website, Platform and Services it offers and provides, is merely a technology platform, and is not your broker, intermediary, agent, or legal advisor and has no fiduciary relationship or obligation to you in connection with any decisions or activities effected by you using the Website, Platform or the Services. No communication or information provided to you by Palmswap is intended as or will be considered or construed as, the solicitation of an offer to buy, investment advice, financial advice, legal advice, or any other sort of advice. All Services, transactions, and Investments will be executed automatically based on the parameters of your consideration. You will be solely responsible for determining whether any Services or investments are suitable and match your interests according to your judgment, objectives, circumstances, and risk tolerance. You will be solely responsible for any losses or liabilities therefrom. You are hereby advised that before using the Website, Platform or Services, including the deployment, operations and management of any smart contracts in general, you should consult with your independent financial, legal, and tax professionals. Palmswap will not be liable for the decisions you make regarding any products and services made available by Palmswap.",
      },

      {
        paragraph: "2",
        text: "YOU AGREE AND HEREBY AUTHORISE PALMSWAP AND ITS AFFILIATES TO TAKE ANY MEASURES IN THEIR SOLE DISCRETION, INCLUDING BUT NOT LIMITED TO, FORCED POSITION REDUCTION AND LIQUIDATION UNDER MARKET VOLATILITY, ILLIQUIDITY AND OTHER CIRCUMSTANCES, FOR THE PURPOSES OF MITIGATING POTENTIAL LOSSES TO YOU, OTHER USERS, AND THE SERVICE PROVIDER AND ITS AFFILIATES. ",
      },

      {
        paragraph: "3",
        text: "By trading Perpetual Futures Contracts on the Platform, you acknowledge and agree that you have sufficient investment knowledge, financial expertise, and experience and the capacity to take on the increased risks arising from Perpetual Futures Contract trading. You further agree to independently assume all the risks arising from conducting Perpetual Futures Contract trading on your own account. If you are uncomfortable with this level of risk, you should not trade Perpetual Futures Contracts.",
      },

      {
        paragraph: "4",
        text: "We and our affiliates do not take any responsibility whatsoever for any losses or damage incurred as a result of your trading perpetual futures contracts on the platform or your failure to understand the risks associated with perpetual futures contract trading.",
      },
      {
        paragraph: "4.1",
        text: "Your use of your Wallet is at your own risk and responsibility and subjected to each Wallet supplier’s applicable terms. Palmswap makes no representation or warranties whatsoever, nor does it provide any recommendation or advice with respect to any Wallet. ",
      },
      {
        paragraph: "4.2",
        text: "Please make sure you carefully review the command lines created by your Wallet, and that it reflects your desired actions, before you approve and execute any action or transaction via your Wallet.",
      },
      {
        paragraph: "4.3",
        text: "We don’t, and will not have any access to your Wallet or any type of cryptocurrencies or other assets which you may have in your Wallet, and we will never ask you for any details other than the Wallet’s public address. We will have no liability to you or to any third party for any claims or damages that may arise as a result of any actions or transactions that you engage in while using the Website.",
      },
      {
        paragraph: "5",
        text: "No deposit protection. We are not required and therefor do not maintain any public or private deposit insurance protection.",
      },
      {
        paragraph: "6",
        text: "Risk of loss in value. PALM Tokens or any digital currencies are not issued by any central banks or national, supra-national, or quasi-national organizations. They are also not backed by any hard assets or other credit. The value of tokens such as the PALM Tokens or any Underlying Digital Asset is affected by several factors, including but not limited to, the total number of tokens or any digital currencies in existence, the continued willingness of market participants to exchange government-issued currency for tokens or digital currencies, purchasers' expectations with respect to the rate of inflation of fiat currencies, purchasers' expectations with respect to the rate of deflation of cryptocurrencies, interest rates, currency exchange rates, cyber theft of cryptocurrencies from online digital wallet providers, or news of such theft from such providers or individuals' digital wallets, investment and trading activities of large investors, monetary policies of the governments, trade restrictions, currency devaluations and revaluations, regulatory measures, the global or regional political, economic or financial events and situations. Thus, all these factors will affect the value of tokens or digital currencies, which may result in the permanent partial or total loss of the value of the Company, a particular token or digital currency. No one will be obliged to guarantee the liquidity or the market price of any of the PALM Token or Digital Assets maintained in your Wallet. The volatility and unpredictability of the value of tokens or digital currencies relative to the government-issued currency may result in a significant loss over a short period of time.",
      },
      {
        paragraph: "7",
        text: "The regulatory regime governing tokens or digital currencies. The regulatory framework relating to tokens or digital currencies remains unsettled, and any laws, regulations, or guidelines may be significantly revised and amended which will materially and adversely affect the value of tokens or digital currencies and Services on the Website or Platform as follows.",
      },
      {
        paragraph: "8",
        text: "Technical and system failure affected the obligations stipulated in these ToU the Company may experience system failures, unplanned interruptions in its Binance Smart Chain Network or other services, hardware or software defects, security breaches or other causes that could adversely affect the Company’s infrastructure network, which includes the Services, Website and Platform.",
      },
      {
        paragraph: "9",
        text: "The Company is unable to anticipate the occurrence of hacks, cyber-attacks, mining attacks, including but not limited to double-spend attacks, majority mining power attacks and selfish-mining attacks, distributed denial of service attacks or errors, vulnerabilities or defects on the Website, Services or the Platform, PALM Token, Users' Wallets or any technology, including but not limited to smart contract technology. Also, the Company is unable to detect the hacks as mentioned earlier, mining attacks, cyber-attacks, distributed denials of service errors vulnerabilities, or defects in a timely manner and does not have sufficient resources to efficiently cope with multiple service incidents happening simultaneously or in rapid succession.",
      },
      {
        paragraph: "10",
        text: "In addition, the Company's network or services could be disrupted by numerous events, including natural disasters, equipment breakdown, network connectivity downtime, power losses, or even intentional disruptions of its services, such as disruptions caused by software viruses or attacks by unauthorized users, some of which are beyond the Company's control. Although the Company has taken steps and used its best endeavor against malicious attacks on its appliances or its infrastructure, which are critical for the maintenance of the Website, Platform and the Services, there can be no assurance that cyber-attacks, such as distributed denials of the Service, will not be attempted in the future, and that the Company's enhanced security measures will be effective. Any significant breach of the Company's security measures or other disruptions resulting in a compromise of the usability, stability and security of the Company's network or the Services, including the Platform, may adversely affect PALM Token.",
      },
      {
        paragraph: "11",
        text: "The Company will have no liability for any delay, error, interruption, or failure to perform any obligation under these ToU where the delay or failure is directly or indirectly resulting from any causes beyond the Company's control, including, but not limited to: (a) Acts of God, nature, court or government; (b) Failure or interruption of public or private telecommunication networks, the failure of Binance Smart Chain Network, communication channels or information systems; (c) Acts or omission of a party for whom the Company is not responsible; (d) Delay, failure, or interruption in, or unavailability of, third-party services; and (e) Strikes, lockouts, labor disputes, wars, terrorist acts and riots.",
      },
      {
        paragraph: "12",
        text: "YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES AND THE WEBSITE IS COMPLETELY AT YOUR OWN RISK. THIS CLAUSE IS NOT EXHAUSTIVE AND DOES NOT DISCLOSE ALL THE RISKS ASSOCIATED WITH DIGITAL BLOCKCHAIN BASED ASSETS AND THE USE OF SERVICES. THEREFORE, YOU ARE RECOMMENDED TO CAREFULLY CONSIDER WHETHER SUCH USE IS SUITABLE FOR YOU IN LIGHT OF YOUR JUDGEMENT, CIRCUMSTANCES, AND FINANCIAL POSITION.",
      },
    ],
  },
  {
    letter: "G",
    title: "Warranty; Limitation of Liability; Indemnification",

    paragraphs: [
      {
        paragraph: "",
        text: "THE CONTENT ON THE WEBSITE IS STRICTLY FOR INFORMATIONAL PURPOSES. PALMSWAP EXPRESSES NO OPINION AS TO THE FUTURE OR EXPECTED VALUE OF ANY CURRENCY, DIGITAL ASSET (INCLUDING PALM TOKEN) OR OTHER INTEREST. PALMSWAP DOES NOT EXPLICITLY OR IMPLICITLY RECOMMEND OR SUGGEST THE PURCHASE OF ANY CURRENCY, DIGITAL ASSET, CRYPTO ASSET (INCLUDING PALM TOKEN) OR OTHER INTEREST NOR ANY INVESTMENT STRATEGY OF ANY KIND. NOTHING ON OR IN THE WEBSITE SHALL CONSTITUTE OR BE CONSTRUED AS AN OFFERING OF ANY CURRENCY, DIGITAL ASSET, CRYPTO ASSET OR ANY FINANCIAL INSTRUMENT.",
      },
      {
        paragraph: "",
        text: "Assumption of Risk. You agree that your use of the Services shall be at your sole risk. To the fullest extent permitted by law, Palmswap, its officers, directors, employees, and agents disclaim all warranties, explicit or implied, in connection with the Services and your use thereof including implied warranties of merchantability, title, fitness for a particular purpose or non-infringement, usefulness, authority, accuracy, completeness, and timeliness. In no event shall Palmswap be liable for any unauthorized access to or use of third party materials, secure servers and/or any and all personal information and/or financial information stored therein. ",
      },
      {
        paragraph: "",
        text: "Status. You understand that the Website, Platform, Services, and any other deliverable originating from the Services are provided to you ‘AS IS’ and ‘AS AVAILABLE’. Palmswap does not warrant that the features, and functions contained in the PALM Platform the Website and the Services will satisfy your preferences, or will fit any purpose. You hereby agree and acknowledge that your access and use of the Website and the Services are at your own risk, and you will be liable for any responsibility, consequences that may arise out of or in connection with the usage or accessibility of the Website, Platform and/or the Services. You expressly agree that the Company will have absolutely no liability in this regard. Furthermore, Palmswap makes no warranties or representations about the accuracy or completeness of the Services, including without derogating, the values of the PALM Tokens (if presented in the Website), and the content of any sites linked to the Services, of the Third Party Materials (as defined below) and assumes no liability or responsibility for any:",
        subparagraphs: [
          {
            subparagraph: "I",
            text: "Errors, mistakes, inaccuracies, non-suitability or non-conformity of any content including without limitation content related to linked applications; Any such content is under the responsibility of the applicable content provider.",
          },
          {
            subparagraph: "II",
            text: "Direct, indirect, special, incidental, punitive or consequential damages including without derogating personal injury, property damages and/or monetary damages, of any nature whatsoever, arising out of the use of or the inability to use the Services;",
          },
          {
            subparagraph: "III",
            text: "Any unauthorized access to or use of Palmswap secure servers and/or any and all personal information and/or financial information stored therein;",
          },
          {
            subparagraph: "IV",
            text: "Any interruption or cessation of transmission to or from the Website, Platform and/or Services;",
          },
          {
            subparagraph: "V",
            text: "Any bugs, viruses, Trojan horses, or the like which may be transmitted to or through the Services by any third party; or",
          },
          {
            subparagraph: "VI",
            text: "Any errors or omissions in any content or for any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available via the Services. ",
          },
        ],
      },

      {
        paragraph: "",
        text: "Without derogating from the abovementioned, in no event will Palmswap, its directors, officers, agents, contractors, partners, consultants and/or employees, be liable to you or any third person for any special, direct, indirect, incidental, special, punitive, or consequential damages whatsoever including any lost profits or lost data arising from your use of the Services or other materials on, accessed through or downloaded from the Services, whether based on warranty, contract, tort, or any other legal theory, and whether or not Palmswap has been advised of the possibility of these damages. The foregoing limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. You specifically acknowledge that Palmswap shall not be liable for any use of smart contracts and/or defamatory, offensive and/or illegal conduct by you or any third party, and that the risk of harm or damage from and/or associated with the foregoing rests entirely with you. ",
      },
      {
        paragraph: "",
        text: "You agree to indemnify and hold Palmswap, and each of its directors, officers, agents, contractors, partners and employees, harmless from and against any loss, liability, claim, demand, damages, costs and expenses, including reasonable attorney's fees, arising out of or in connection with any of the following: ",
        subparagraphs: [
          {
            subparagraph: "a",
            text: "Your use of and access to the Services;",
          },
          {
            subparagraph: "b",
            text: "Your violation of any term of these ToU;",
          },
          {
            subparagraph: "c",
            text: "Your violation of any third party right, including without limitation any copyright, property, or privacy right;",
          },
          {
            subparagraph: "d",
            text: "Any claim that any User Submission made by you has caused damage to a third party; or",
          },
        ],
      },
      {
        paragraph: "",
        text: "You acknowledge and agree that, to the fullest extent permitted by any applicable law, the disclaimers contained herein shall apply to any and all damages or injuries whatsoever caused by or related to the use of, or inability to use, the Services, under any cause or action whatsoever of any jurisdiction, including, without limitation, actions for breach of warranty, breach of contract or tort (including negligence). Under no circumstances whatsoever will Palmswap, even if advised of the possibility of such damages, be responsible or liable to you or to any other entity for any compensatory, indirect, incidental, consequential (including for loss of profits, lost business opportunities, loss of goodwill or data destruction or impairment) special, exemplary, or punitive damages that result from or relate in any manner whatsoever to your use of or inability to use the Services. If you are dissatisfied with the Services, or with these terms, or you have any dispute with Palmswap, your sole and exclusive remedy is to discontinue using the Services.",
      },

      {
        paragraph: "",
        text: "Distributions not supported. Certain digital assets (for the purposes hereof, ‘digital assets’ include Underlying Digital Assets and your Collateral) may be built on protocols that support digital asset distributions, including, but not limited to, forks, staking rewards and airdrops (if applicable). Palmswap is not obligated to support any such digital asset distributions for Users as such they may not be supported. ",
      },
      {
        paragraph: "",
        text: "Unclaimed or Abandoned Funds. We do not have any ability to access funds held in your Account. You are solely responsible for such funds, Palmswap shall not be held liable with respect to such assets unclaimed or abandoned property.",
      },
      {
        paragraph: "",
        text: "To the extent feasible, we retain the right to freeze or to take any action we deem as necessary with respect to any funds held in your Account if we suspect or believe that such funds may be involved with illegal activities. ",
      },
    ],
  },
  {
    letter: "H",
    title: "Third Party Material",
    paragraphs: [
      {
        paragraph: "1",
        text: "You may be able to access, review, display or use third party services, resources, content, information or links to other World Wide Web sites or resources ('Third Party Materials') via the Services, including, without limitation, KYC service providers, Wallet providers including Scatter, and DSPs. You acknowledge sole responsibility for and assume any and all risks arising from your access to, use of or reliance upon any such Third Party Materials, and Palmswap disclaims any liability that you may incur arising from your access to, use of or reliance upon such Third Party Materials through the Services. You acknowledge and agree that Palmswap: (a) is not responsible for the availability, accuracy integrity, quality or lawfulness of such Third Party Materials or the products or services on or available from such Third Party Materials; (b) has no liability to you or any third party for any harm, injuries or losses suffered as a result of your access to or use of such Third Party Materials; and (c) does not make any promises to remove Third Party Materials from being accessed through the Website and Services. Your ability to access or link to Third Party Materials or third-party services does not imply any endorsement by Palmswap of Third Party Materials or any such third-party services.",
      },
      {
        paragraph: "2",
        text: "These ToU do not authorize you to, and you may not use any Third Party Materials except as expressly permitted by the owners of such Third Party Materials and such owners may have the right to seek damages against you for any unauthorized use of their Third Party Materials. ",
      },
      {
        paragraph: "3",
        text: "Without derogating from any of Palmswap's rights and remedies under these ToU and/or under law, Palmswap will be entitled, at its sole discretion, to immediately discontinue the Services or any part thereof, in the event of any alleged infringement, misappropriation or violation of any rights of any third parties in connection with the Third Party Materials. ",
      },
      {
        paragraph: "4",
        text: "You may not use any Third Party Materials for which you have not obtained appropriate approval to use. Palmswap cannot grant permission to use third-party content. ",
      },
      {
        paragraph: "5",
        text: "You acknowledge and agree that Palmswap will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such site or resource. ",
      },
    ],
  },
  {
    letter: "I",
    title: "Notices",
    titleExtra:
      "Notices to you may be made via the Services and/or email. Palmswap may also provide notices of changes to these ToU or other matters by displaying notices or links to notices to you generally on the Services. You agree that all agreements, notices, disclosures and any other communications that Palmswap provide as aforementioned satisfy any legal requirement that such communications be in writing. ",
    paragraphs: [],
  },
  {
    letter: "J",
    title: "Termination",
    paragraphs: [
      {
        paragraph: "",
        text: "These ToU will be immediately terminated by discontinuing your use of or participate in the Services and you agree to terminate the accessibility on the PALM’s Platform.",
      },
      {
        paragraph: "",
        text: "These ToU can be suspended or terminated without a notice from the Company if there is a reasonable ground for the Company to believe that you have breached any of the terms or provisions stipulated in these ToU, or if you do not comply with these ToU.",
      },
      {
        paragraph: "",
        text: "The termination of these ToU will not prevent the Company from seeking remedies from you in the case where you have breached any terms or provisions of these term before such termination. The Company will not be liable to you or to any third party for any termination, suspension, or modification of your access to the Services.",
      },
      {
        paragraph: "",
        text: "Any ongoing obligation to you as well as the provisions regarding (i) PALM's Intellectual Property, (ii) No solicitation or Offering, (iii) Indemnification, (iv) Limitation of liability, and (v) any other provisions designed to survive, will survive any termination or expiration of these ToU for any reason.",
      },
    ],
  },
  {
    letter: "K",
    title:
      "Governing Law, Resolving Disputes, Arbitration and Class Action Waiver",
    titleExtra:
      "PLEASE READ THIS SECTION CAREFULLY AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING A CLASS ACTION.",

    paragraphs: [
      {
        paragraph: "1",
        text: "Notice of Claim and Dispute Resolution Period. Please contact the Company first. The Company will seek to address your concerns without resorting to formal legal proceedings whenever possible. If you have a dispute with the Company, you should contact the Company, and a case number will be assigned. The Company will attempt to resolve your dispute internally as soon as possible. The parties will agree to negotiate in good faith to resolve the dispute and discussions will remain confidential and subject to applicable laws protecting settlement discussions from use as evidence in any legal proceeding.",
      },
      {
        paragraph: "2",
        text: "Agreement to Arbitrate. You and the Company agree that subject to terms hereof, any dispute, claim, or controversy between you and the Company that arises in connection with, or relating in any way, to these ToU, or to your relationship with the Company as a user of the Services (whether by contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of these ToU) will be determined by a mandatory final and binding individual arbitration rather than a class action, except as set forth below under Exceptions to the Agreement to Arbitrate. You and the Company further agree that the arbitrator will have the exclusive power to rule on his or her jurisdiction, including, without limitation, any objections with respect to the existence, scope, or validity of the Agreement to Arbitrate, or to the arbitrability of any claim or counterclaim. Arbitration is more informal than a lawsuit in court. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. There may be more limited discovery than in court. The arbitrator must follow this agreement and can award the same damages and relief as a court, including, if applicable, attorney fees, except the arbitrator may not award declaratory or injunctive relief benefiting anyone but the parties to the arbitration. The arbitration provisions set forth in Clause 16 will survive termination of these ToU.",
      },
      {
        paragraph: "3",
        text: "Governing Law/Jurisdiction. The governing law of the arbitration will be that of the British Virgin Islands.",
      },
      {
        paragraph: "4",
        text: "Confidentiality. The parties agree that the arbitration will be kept confidential. The existence of the arbitration, any non-public information provided in the arbitration, and any submissions, orders or awards made in the arbitration will not be disclosed to any non-parties except the tribunal, the parties, their counsel, experts, witnesses, accountants and auditors, insurers and reinsurers, and any other person necessary to facilitate the arbitration. Notwithstanding the preceding, a party may disclose information to the extent that disclosure may be required to fulfil a legal duty, protect, or pursue a legal right, or enforce or challenge an award in bona fide legal proceedings. This confidentiality provision will survive the termination of these ToU and any arbitration brought under these ToU.",
      },
      {
        paragraph: "5",
        text: "Class Action Waiver. You and the Company agree that any claims relevant to these ToU, or your relationship with the Company will be brought against the other party in arbitration on an individual basis only and not as a plaintiff or class member in a purported class or representative action. You and the Company further agree to waive any right for such claims to be brought, heard, or arbitrated as a class, collective, representative, or private attorney general action, to the extent permissible by applicable laws. Combining or consolidating individual arbitrations into a single arbitration is not permitted without the consent of all parties involved.",
      },
      {
        paragraph: "6",
        text: "The Company reserves the right to update, modify, revise, suspend, or make future changes to these terms regarding the parties’ Agreement to Arbitrate, subject to applicable laws. You hereby consent and agree that it is your responsibility to ensure that your understanding of this Clause is up to date. Subject to applicable laws, your continued use of your PALM’s account will be interpreted as your acceptance of any modifications to these terms regarding the parties’ Agreement to Arbitrate. You agree that if you object to the modifications to these terms, the Company may block access to your account pending closure of your account. In such circumstances, these ToU prior to modification will remain in full force and affect the pending closure of your accessibility.",
      },
    ],
  },

  {
    letter: "L",
    title: "Miscellaneous",

    paragraphs: [
      {
        paragraph: "1",
        text: "Severability. If any of the provisions in these ToU is found by a court of competent authority to be invalid, void, unlawful or unenforceable under any applicable laws, such unenforceability or invalidity will not render these ToU unenforceable or invalid as a whole, and such provisions will be deleted without affecting the remaining provisions herein.",
      },
      {
        paragraph: "2",
        text: "Variation of ToU.  The Company has the right to revise these ToU at its sole discretion at any time, and by using the Website or other PALM’s platform, you will be expected to review such ToU regularly to ensure that you understand all provisions stipulated in these ToU.",
      },
      {
        paragraph: "3",
        text: "Assignment. The Company will be allowed to assign, transfer, and subcontract Our rights and/or obligations under these ToU without the need to provide you any notification or acquire your consent. Nevertheless, you will not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these ToU.",
      },
      {
        paragraph: "4",
        text: "Entire Agreement. These ToU, including the Privacy Policy and any rules contained on the Website or others PALM Platform, constitute the sole and entire agreement between You and the Company with respect to your use of the PALM Platform, and supersedes other prior or contemporaneous negotiations, discussions, agreements, understandings, representations, and warranties, both written and oral, between You and the Company with respect to such subject matter.",
      },
      {
        paragraph: "5",
        text: "No Third-Party Rights. Nothing in these ToU will be deemed to create any rights to any creditors or other persons, not a party hereto. Moreover, these ToU will not be construed, in any respect, to be a contract, in whole or in part, for the benefit of any third parties.",
      },
      {
        paragraph: "6",
        text: "Clickwrap. The Company may deliver the Service through electronic means such as download links, graphical tools or other technologies for providing the Service for users. The user interfaces to such electronic means may require that the users agree to these ToU by checking a box, clicking a button, or continuing with the Service. If user through such action the user becomes a Party to these ToU. Such an action of acceptance shall be sufficient to bind the users to the terms and conditions herein these ToU. ",
      },
      {
        paragraph: "7",
        text: "Waiver. The failure of one Party to require the performance of any provision will not affect that Party’s right to require performance at any time thereafter. At the same time, the waiver of one Party to seek recovery for the other Party’s violation of these ToU of any provisions of applicable terms will not constitute a waiver by that Party of any subsequent breach or violation by the other Party or of the provision itself.",
      },
    ],
  },
];

const TermsPage = () => {
  const termsParagraphs = terms.slice(0, 12);

  return (
    <MainContainer>
      <Title>Terms of Service</Title>
      <Description>Last Updated: Tue May 4, 2023</Description>
      <TermsContainer>
        <Navs>
          {terms.map((nav, i) => (
            <NavItem key={nav.title + i} href={`/terms_of_use#${nav.letter}`}>
              <NavLetter>{nav.letter}.</NavLetter>
              <NavTitle>{nav.title}</NavTitle>
            </NavItem>
          ))}
        </Navs>
        <Contents>
          <ContentsTopSection>
            <ContentsTopSectionTitle>
              PERPETUAL FUTURES CONTRACTS CARRY A SUBSTANTIAL AMOUNT OF RISK FOR
              YOUR FUNDS, WE DO NOT OFFER ANY FINANCIAL OR OTHER ADVICE. PLEASE
              BE AWARE OF THE RISKS AND DO NOT USE PALMSWAP’S SERVICES IF YOU DO
              NOT HAVE A SUFFICIENT KNOWLEDGE OF ANY APPLICABLE FIELD OF
              EXPERTISE, OR RESIDE IN A RESTRICTED TERRITORY.
            </ContentsTopSectionTitle>
            <ContentsTopSectionText>
              These Terms of Use “ToU”, as may be amended and altered from time
              to time, govern your relationship with Palm Labs Inc. a company
              incorporated in the British Virgin Islands, with company number
              2098129 and its affiliates (“we”, “us”, “our” and/or “Palmswap”
              and/or “Company”), together with any other documents which apply
              or may apply and are represented in www.palmswap.org (the “ToU”
              and the “Website”).
            </ContentsTopSectionText>
            <ContentsTopSectionText>
              By using the Platform (as hereinafter defined), you or any legal
              entity which you represent in connection with your use of the
              Website and the Platform (“you” or “User”), you agree with the
              following ToU in its entirety, including the Privacy Policy, Fees,
              and any other documents which may apply (which constitutes an
              agreement between you and Palmswap).
            </ContentsTopSectionText>
            <ContentsTopSectionText>
              Please carefully read and review these ToU prior to using the
              Platform, and any feature the Platform contains or may contain for
              the purpose of trading blockchain-based assets.THE WEBSTIRE AND/OR
              THE PLATFORM ARE NOT OFFERED TO RESTRICTED TERRITORIES (as defined
              herein)
            </ContentsTopSectionText>
          </ContentsTopSection>
          <TermsSection>
            {termsParagraphs.map((term, i) => (
              <Term key={term.title + i} id={`${term.letter}`}>
                <TermTitle>
                  {term.letter}. {term.title}
                </TermTitle>
                <TermTitleExtra>{term.titleExtra}</TermTitleExtra>
                <TermParagraphs>
                  {term.paragraphs.map((p, i) => (
                    <div key={p.paragraph + i}>
                      <TermParagraph>
                        <TermParagraphsNumber>
                          {p.paragraph}.
                        </TermParagraphsNumber>
                        <TermParagraphsText>{parse(p.text)}</TermParagraphsText>
                      </TermParagraph>
                      {p.subparagraphs &&
                        p.subparagraphs.map((sp, j) => (
                          <TermSubParagraph key={sp.subparagraph + j}>
                            <TermSubParagraphsNumber>
                              {sp.subparagraph}.
                            </TermSubParagraphsNumber>
                            <TermSubParagraphsText>
                              {parse(sp.text)}
                            </TermSubParagraphsText>
                          </TermSubParagraph>
                        ))}
                    </div>
                  ))}
                </TermParagraphs>
              </Term>
            ))}
          </TermsSection>
        </Contents>
      </TermsContainer>
    </MainContainer>
  );
};

export default TermsPage;
