import { SectionHeader } from "components/Common/styled";
import { CardMuted } from "components/Common/styled";
import {
  Box,
  Button,
  styled,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: 150,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 60,
  },
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  padding: 24,
  zIndex: 5,
  background: "#000000cc",
}));

export const Modal = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  gap: 24,
  padding: "60px 60px 30px",
  position: "relative",
  background: colors.gradientMuted,
  width: 420,
  borderRadius: 30,
  [theme.breakpoints.down("sm")]: {
    width: 350,
    padding: "60px 40px 24px",
  },
}));

export const ModalHeader = styled(SectionHeader)(({ theme }) => ({
  color: "#F1CB80",
  fontSize: 28,
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
  },
}));

export const CloseButton = styled(IconButton)(() => ({
  width: 35,
  height: 35,
  background: colors.border,
  position: "absolute",
  top: 25,
  right: 30,
}));

export const CloseIcon = styled(Close)(() => ({
  pointerEvents: "none",
  fontSize: 15,
  color: colors.white,
}));

export const LeftTop = styled("img")(({ theme }) => ({
  width: "35%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const RightBottom = styled(CardMuted)(({ theme }) => ({
  width: "65%",
  padding: 28,
  display: "flex",
  flexDirection: "column",
  gap: 30,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  textAlign: "center",
  margin: "40px 0 10px",
  fontWeight: "bold",
  color: colors.white,
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
    width: "100%",
    height: "auto",
  },
}));

export const Form = styled("form")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 20,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
  },
}));

export const SubmitButton = styled(Button)(({ theme, disabled }) => ({
  margin: "40px 0 10px",
  color: "#ffffff !important",
}));

export const InputContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 10,
  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
}));

export const InputStyled = styled(TextField)(() => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    backgroundColor: colors.background5,
    height: 55,
    borderRadius: 15,
    fontSize: 14,
    "& fieldset": {
      borderColor: colors.border,
    },
    "&:hover fieldset": {
      borderColor: colors.border,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.border,
      borderWidth: 1,
    },
    "& input::placeholder": {
      color: colors.inactive,
    },
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
}));
