import React from "react";
import { ButtonProps } from "@mui/material";
import {
  ArrowsBox,
  LinkButtonIcon,
  LinkButtonIconSecond,
  LinkButtonStyled,
} from "./styled";

interface LinkButtonProps extends ButtonProps {
  href?: string;
  target?: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({ href, target, ...props }) => {
  return (
    <a href={href} target={target} style={{ textDecoration: "none" }}>
      <LinkButtonStyled
        {...props}
        variant="contained"
        endIcon={
          <ArrowsBox className="arrows-box">
            <LinkButtonIcon />
            <LinkButtonIconSecond />
          </ArrowsBox>
        }
      ></LinkButtonStyled>
    </a>
  );
};

export default LinkButton;
