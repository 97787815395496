import {
  Description,
  DescriptionContainer,
  SectionHeader,
} from "components/Common/styled";
import {
  Card,
  CardContainer,
  CardDesctiptionContainer,
  CardDesctiptionItem,
  CardDesctiptionList,
  CardImg,
  DesctiptionItemText,
  LinkBtn,
  MainContainer,
  WhiteText,
} from "./styled";


import plpIcon from 'assets/PLP.png';
import palmIcon from 'assets/palmLogo.svg';



const Ecosystem = () => {

  return (
    <MainContainer>
      <DescriptionContainer>
        <SectionHeader>Two tokens, one ecosystem</SectionHeader>
        <Description>
          Take advantage of our stake-and-forget mechanisms to earn a share of the trading fees.
        </Description>
      </DescriptionContainer>
      <CardContainer>
        <Card>
          <WhiteText>PLP</WhiteText>
          <Description>Liquidity provider token</Description>
          <CardImg src={plpIcon}/>
          <CardDesctiptionContainer>
            <CardDesctiptionList>
              <CardDesctiptionItem>
                <DesctiptionItemText>Mint by providing liquidity in USDT</DesctiptionItemText>
              </CardDesctiptionItem>
              <CardDesctiptionItem>
                <DesctiptionItemText>Earn a 40% share of trading fees</DesctiptionItemText>
              </CardDesctiptionItem>
              <CardDesctiptionItem>
                <DesctiptionItemText>Mint and Burn fees which are 0.3%</DesctiptionItemText>
              </CardDesctiptionItem>
              <CardDesctiptionItem>
                <DesctiptionItemText>Borrow fees paid from traders hourly</DesctiptionItemText>
              </CardDesctiptionItem>
            </CardDesctiptionList>
          </CardDesctiptionContainer>
          <LinkBtn href="https://synthetic.palmswap.org/#/buy" target="_blank">Provide Liquidity</LinkBtn>
        </Card>
        <Card>
          <WhiteText>PALM</WhiteText>
          <Description>Palmswap’s governance token</Description>
          <CardImg src={palmIcon}/>
          <CardDesctiptionContainer>
            <CardDesctiptionList>
              <CardDesctiptionItem>
                <DesctiptionItemText>Stake to earn 30% of the trading fees in USDT</DesctiptionItemText>
              </CardDesctiptionItem>
              <CardDesctiptionItem>
                <DesctiptionItemText>Trade to earn PALM tokens through Trading Epoches</DesctiptionItemText>
              </CardDesctiptionItem>
              <CardDesctiptionItem>
                <DesctiptionItemText>Reduce trading fees when staking PALM (Upcoming)</DesctiptionItemText>
              </CardDesctiptionItem>
              <CardDesctiptionItem>
                <DesctiptionItemText>Increase Affiliate commission when staking PALM (Upcoming)</DesctiptionItemText>
              </CardDesctiptionItem>
            </CardDesctiptionList>
          </CardDesctiptionContainer>
          <LinkBtn href="https://pancakeswap.finance/swap?chain=bsc&outputCurrency=0x29745314B4D294B7C77cDB411B8AAa95923aae38" target="_blank">Buy PALM</LinkBtn>
        </Card>
      </CardContainer>
    </MainContainer>
  );
};

export default Ecosystem
