import { Box, styled, Typography } from "@mui/material";
import LinkButton from "components/Common/LinkButton";
import { CardMuted } from "components/Common/styled";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 18,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: 18,
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

export const InfoCardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: 18,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));

export const Bold = styled(Typography)(() => ({
  fontWeight: "bold",

}));

export const TokenCard = styled(CardMuted)(() => ({
  cursor: "pointer",
  width: "100%",
  display: "flex",
  gap: 20,
  flexDirection: "column",
  padding: 20,
  "&:hover": {
    '& .MuiButtonBase-root': {
      opacity: 1,
    },
  },
}));

export const TokenCardRow = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const TokenImg = styled('img')(() => ({
  width: 35,
  height: 35
}));

export const TokenLink = styled(LinkButton)(() => ({
  '&.MuiButtonBase-root': {
    minHeight: 40,
    height: 40,
    opacity: 0,
  }
}));

export const LinkBtn = styled(LinkButton)(() => ({
  '&.MuiButtonBase-root': {
    minHeight: 40,
    height: 40,
  }
}));

export const TokenCardTop = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

export const TokenName = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const TokenStats = styled(Box)({
  display: "flex",
  gap: 10,
  flexDirection: "row",
});

export const TokenStatContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const WhiteText = styled(Typography)({
  fontSize: 14,
  color: colors.white,
});

export const TokenPrice = styled(Typography)({
  fontSize: 24,
  color: colors.white,
});

export const Card = styled(CardMuted)(() => ({
  cursor: "pointer",
  width: "100%",
  display: "flex",
  gap: 20,
  flexDirection: "column",
  padding: 20,

  "&:hover": {
    "& .MuiTypography-root": {
      color: colors.white,
    },
    "& .MuiButton-root": {
      color: colors.black,
      background: colors.white,
    },
    "& .MuiSvgIcon-root": {
      color: colors.black,
    },
    background: colors.purpleMuted,
  },
}));

export const CardHead = styled(Box)(() => ({
  display: 'flex',
  alignItems: "center",
}));

export const CardDesctiptionContainer = styled(Box)(() => ({
  height: '100%',
  gap: 15,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
}));

export const CardImg = styled("img")(() => ({
  width: 35,
  height: 35,
  marginRight: 10,
}));