import { Box, styled, Typography } from "@mui/material";
import { CardMuted } from "components/Common/styled";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: 18,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));


export const WhiteText = styled(Typography)({
  fontSize: 22,
  color: colors.white,
});

export const TokenPrice = styled(Typography)({
  fontSize: 32,
  color: colors.white,
});

export const Card = styled(CardMuted)(() => ({
  cursor: "pointer",
  width: "100%",
  display: "flex",
  gap: 20,
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: 20,
}));

export const CardDesctiptionContainer = styled(Box)(() => ({
  height: '100%',
  gap: 20,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
}));

export const CardImg = styled("img")(() => ({
  height: 110,
  marginRight: 10,
}));