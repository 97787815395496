import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    cancel: true;
  }
}
// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1288,
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: "#030303",
    },
    text: {
      primary: "#ffffff",
      secondary: "#8568DB",
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 500,
          boxShadow: "none",
          padding: "14px 22px",
          height: 50,
          minHeight: "fit-content",
          color: colors.white,
          fontSize: 14,
        },
        containedPrimary: {
          transition: "0.2s",
          backgroundSize: "200% auto",
          borderRadius: 500,
          background: colors.gradientMain,
          "&:hover": {
            background: colors.white,
            color: colors.black,
            "& .MuiSvgIcon-root": {
              color: colors.black,
            }
          }
        },
        containedSecondary: {
          background: colors.background2,
          boxShadow: "none",
          borderRadius: 500,
          '&:hover': {
            background: colors.background2,
          }
        },
      },
    },
    MuiTypography: {
    },
  },
});
export default theme;
