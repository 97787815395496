import { Box, styled} from "@mui/material";
import {
  DescriptionContainer,
} from "components/Common/styled";

export const MainContainer = styled(Box)(({theme})=>({
  width: "100%",
  height: 600,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: -150,
  paddingTop: 150,
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
    marginTop: -300,
    paddingTop: 300
  },
}));

export const DescriptionContainerStyled = styled(DescriptionContainer)(()=>({
  marginBottom: 0,
  width: '100%',
}));

export const GridLines = styled('img')(()=>({
  height: "100%",
  top: 0,
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, 0)",
  zIndex: -1,
}));
