import { Box, styled, Link, Typography } from "@mui/material";
import { colors } from "config/colors";

export const StyledLinkContainer = styled(Box)({
  position: "relative",
  "&:hover": {
    "& .links": {
      width: 268,
      height: 360,
    },
    "& .explore": {
      width: 540,
      height: 475,
    },
  },
  "& .links": {
    width: 0,
    height: 0,
    overflow: "hidden",
  },
  "& .explore": {
    width: 0,
    height: 0,
    overflow: "hidden",
  },
});

export const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }: { active?: boolean }) => ({
  padding: "10px",
  display: "flex",
  color: active ? colors.white : colors.inactive,
  textDecoration: "none",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: active ? "bold" : "normal",
  position: "relative",
}));

export const AnimationSubLinkContainer = styled(Box)({
  position: "absolute",
  top: "40px",
  left: "0px",
});

export const StyledSubLinkContainer = styled(Box)({
  position: "absolute",
  top: "0px",
  left: "0px",
  width: 268,
  height: "auto",
  paddingTop: 4,
  paddingBottom: 8,
  paddingLeft: 8,
  paddingRight: 8,
  background: "#09090B",
  borderRadius: 20,
  border: "1px solid rgba(255, 255, 255, 0.06)",
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
});

export const StyledMenuText = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
});

export const StyledMenuItem = styled(Link)({
  display: "flex !important",
  borderRadius: 10,
  padding: 10,
  gap: 6,
  cursor: "pointer",
  textDecoration: "none",
  "&:hover": {
    background: colors.border,
  },
});

export const StyledTitle = styled(Typography)({
  margin: 10,

  color: colors.inactive,
  fontSize: 10,
  textTransform: "uppercase",
});

export const StyledMenuHeader = styled(Box)({
  fontSize: 14,
  color: colors.white,
  fontWeight: "bold",
});

export const StyledDescription = styled(Typography)({
  fontSize: 11,
  color: colors.inactive,
});

export const MenuAnimationContainer = styled(Box)({
  position: "absolute",
  top: "40px",
  left: "0px",
});

export const ExploreMenuContainer = styled(Box)({
  display: "flex",
  position: "absolute",
  top: "0px",
  left: "0px",
  zIndex: 100,
});

export const ExploreMenuLeft = styled(Box)({
  background: "#09090B",
  padding: "14px 10px",
  display: "flex",
  flexDirection: "column",
  borderTopLeftRadius: 20,
  borderBottomLeftRadius: 20,
  width: 270,
  border: "1px solid rgba(255, 255, 255, 0.06)",
  borderRight: "none",
});

export const ExploreMenuRight = styled(Box)({
  background: colors.background1,
  padding: "14px 10px",
  display: "flex",
  flexDirection: "column",
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20,
  width: 270,
});

export const ExploreMenuHeader = styled(Typography)({
  marginLeft: 10,
  marginBottom: 10,
  color: colors.inactive,
  fontSize: 10,
  textTransform: "uppercase",
});

export const ExploreMenuItemsContainer = styled(Box)({
  display: "flex !important",
  flexDirection: "column",
  gap: 6,
  borderRadius: 20,
});

export const ExploreMenuItem = styled(Link, {
  shouldForwardProp: (prop) => prop !== "disabled",
})(({ disabled }: { disabled: boolean }) => ({
  display: "flex !important",
  borderRadius: 10,
  padding: 10,
  gap: 6,
  cursor: !disabled && "pointer",
  textDecoration: "none",
  "&:hover": {
    background: !disabled && colors.border,
  },
}));

export const ExploreMenuItemTextContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
});

export const ExploreMenuItemIcon = styled("img")({
  width: 22,
  height: 22,
});

export const ExploreMenuItemHeaderContainer = styled(Typography)({
  display: "flex !important",
  gap: 6,
  flexWrap: "wrap",
});

export const ExploreMenuItemTag = styled(Typography)({
  height: 20,
  border: "1px solid rgba(106, 63, 233, 0.29)",
  padding: "0 10px",
  textAlign: "center",
  color: colors.main,
  fontSize: 11,
  borderRadius: 7,
});

export const ExploreMenuItemHeader = styled(Typography)({
  fontSize: 14,
  color: colors.white,
  fontWeight: "bold",
});

export const ExploreMenuItemDescription = styled(Typography)({
  fontSize: 11,
  color: colors.inactive,
});

export const ExploreMenuDivider = styled(Box)({
  marginBottom: 12,
  marginTop: 20,
  marginLeft: 10,
  marginRight: 10,
  height: 1,
  width: "calc(100% - 20px)",
  background: colors.border,
});
