import React from "react";
import {
  Highlight1,
  Highlight2,
  Highlight3,
  MainContainer,
  Title,
  Highlights,
  TitleContainer,
  SubTitle,
} from "./styled";

const ConfirmPage = () => {
  return (
    <MainContainer>
      <TitleContainer>
        <Title>Congrats, you are officially subscribed!</Title>
        <SubTitle>
          The latest Palmswap Exchange information will be going straight to
          your inbox.
        </SubTitle>
      </TitleContainer>
      <Highlights>
        <Highlight1 />
        <Highlight2 />
        <Highlight3 />
      </Highlights>
    </MainContainer>
  );
};

export default ConfirmPage;
