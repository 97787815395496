import React from "react"
import BottomSection from "./BottomSection"
import CenterSection from "./CenterSection"
import { CenterBottom, HomePageContainer } from "./styled"
import TopSection from "./TopSection"

const HomePage = () => {
  return (
    <HomePageContainer>
      <TopSection /> 
     <CenterBottom>
        <CenterSection />
        <BottomSection />
      </CenterBottom> 
    </HomePageContainer>
  )
}

export default HomePage