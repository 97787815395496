type SubNav = {
  title: string;
  route: string;
  description?: string;
  target?: string;
  icon?: string;
};

export type NavItem = {
  title: string;
  route: string;
  children?: SubNav[];
  target?: string;
};

export const NAVS: NavItem[] = [
  {
    title: "Explore",
    route: "",
  },
  {
    title: "Docs",
    route: "https://docs.palmswap.org/",
    target: "_blank",
  },
  {
    title: "Community",
    route: "",
    children: [
      {
        title: "Twitter",
        route: "https://twitter.com/Palmswaporg",
        description: "Interact and stay in touch with us",
        icon: "assets/twitter.svg",
        target: "_blank",
      },
      {
        title: "Discord",
        route: "https://discord.com/invite/MQeV5yr3sy",
        description: "Community paradise",
        icon: "assets/discord.svg",
        target: "_blank",
      },
      {
        title: "Medium",
        route: "https://medium.com/@Palmswap",
        description: "The place to gain knowledge",
        icon: "assets/medium.svg",
        target: "_blank",
      },
      {
        title: "Telegram Chat",
        route: "https://t.me/Palmswapchat",
        description: "Meet with other perpetual traders",
        icon: "assets/telegram.svg",
        target: "_blank",
      },
      {
        title: "Telegram Ann",
        route: "https://t.me/PalmswapAnn",
        description: "Don't miss the latest updates",
        icon: "assets/telegram.svg",
        target: "_blank",
      },
    ],
  },
  {
    title: "Company",
    route: "",
    children: [
      {
        title: "Careers",
        route: "https://web3.career/web3-companies/palmswap",
        description: "Find your dream job.",
        icon: "assets/careers.svg",
        target: "_blank",
      },
      {
        title: "Media Kit",
        route: "https://docs.palmswap.org/brand-and-logo",
        description: "All media you need",
        icon: "assets/mediakit.svg",
        target: "_blank",
      },
    ],
  },
  {
    title: "Blog",
    route: "https://blog.palmswap.org",
    target: "_blank",
  },
  {
    title: "Academy",
    route: "https://academy.palmswap.org",
    target: "_blank",
  },
  {
    title: "Liquidity",
    route: "/liquidity",
  },
];
if (process.env.REACT_APP_SHOW_NFT) {
  NAVS.push({
    title: "Alpha NFT",
    route: "/alpha_nft",
  });
}
