export const colors = {
  inactive: '#6C7284',
  main: '#6A3FE9',
  purpleMuted: '#6B3FEA80',
  border: 'rgba(255, 255, 255, 0.06)',
  background1: '#0F1014',
  background2: "#191B1F",
  background5: '#16171D',
  white: '#FFFFFF',
  black: '#000000',
  red: '#FD4040',
  gradientMuted: 'linear-gradient(180deg, #141217 0%, #0e0c12 100%)',
  gradientMain: 'linear-gradient(270deg, #6326fd 0%, #7156d7 100%)',
  gradientDarkPurple: 'linear-gradient(180deg, #09090b 0%, #0e0c12 100%)',
  gradientLight: 'linear-gradient(270deg, #edebf7 0%, #d2cefe 100%)',
  gradientPearl: 'linear-gradient(90deg, #10ad94 0%, #1b85a3 30%, #3e31a5 70%, #0d0553 100%)',
  gradientBlackPurple: 'linear-gradient(47deg, #141217 0%, rgba(122, 56, 255, 0.46) 100%)'
}