import LinkButton from "components/Common/LinkButton";
import { SectionHeader } from "components/Common/styled";
import { DescriptionContainerStyled, GridLines, MainContainer } from "./styled";
import lines from "assets/linesGrid.png";

const BottomSection = () => {
  return (
    <MainContainer>
      <DescriptionContainerStyled>
        <SectionHeader>Explore other avenues <br/> to earn passive income at Palmswap?</SectionHeader>
        <LinkButton href="https://docs.palmswap.org/" target="_blank">
          Explore
        </LinkButton>
      </DescriptionContainerStyled>
      <GridLines src={lines} />
    </MainContainer>
  );
};

export default BottomSection;
