import { Box, styled, Typography } from "@mui/material";
import LinkButton from "components/Common/LinkButton";
import { CardMuted } from "components/Common/styled";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: 18,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));

export const LinkBtn = styled(LinkButton)(() => ({
  '&.MuiButtonBase-root': {
    minHeight: 40,
    height: 40,
  }
}));

export const WhiteText = styled(Typography)({
  fontSize: 28,
  color: colors.white,
  fontWeight: "bold"
});

export const Card = styled(CardMuted)(() => ({
  cursor: "pointer",
  width: "100%",
  display: "flex",
  gap: 20,
  flexDirection: "column",
  padding: 20,
  alignItems: "center",
}));

export const CardDesctiptionContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
}));

export const CardDesctiptionList = styled('ul')(() => ({
  listStyleType: "disc",
}));

export const CardDesctiptionItem = styled('li')(() => ({
  listStyleType: "disc",
  color: colors.main,
}));

export const DesctiptionItemText = styled(Typography)({
  fontSize: 14,
  color: colors.white,
});

export const CardImg = styled("img")(() => ({
  width: 120,
  height: 120,
}));