import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DescriptionContainer } from "components/Common/styled";

export const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 120,
  marginBottom: 300,
  gap: 120,
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    padding: "0 16px",
    flexDirection: "column",
    marginTop: 90,
  },
}));

export const TitleContainer = styled(DescriptionContainer)(({ theme }) => ({
  margin: 0,
  maxWidth: 643,
  [theme.breakpoints.down("sm")]: {
    margin: 0,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  background: "white",
  WebkitBackgroundClip: "text",
  zIndex: 1,
  fontSize: 46,
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    width: "350px",
    fontSize: 30,
    whiteSpace: "normal",
    textAlign: "center",
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  background: "#6C7284",
  color: "#6C7284",
  WebkitBackgroundClip: "text",

  fontSize: 18,

  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    textAlign: "center",
    width: "300px",
  },
}));

export const Highlights = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  zIndex: 0,
}));

const HighlightStyle: any = {
  WebkitFilter: "blur(70px)",
  filter: "blur(70px)",
  zIndex: 1,
  position: "absolute",
  overflow: "hidden",
};

export const Highlight1 = styled(Box)(({ theme }) => ({
  ...HighlightStyle,
  background:
    "radial-gradient(50% 50% at 50% 50%, #6a3fe9 0%, rgba(171, 171, 171, 0) 100%)",
  WebkitFilter: "blur(100px)",
  filter: "blur(100px)",
  width: "90%",
  height: "200%",
  left: "50%",
  top: "-50%",
  transform: "translate(-50%, -50%)",
}));

export const Highlight2 = styled(Box)(({ theme }) => ({
  ...HighlightStyle,
  background:
    "radial-gradient(50% 50% at 50% 50%, #5a9dba 0%, rgba(0, 0, 0, 0) 100%)",
  width: "20%",
  height: "700%",
  left: "10%",
  top: "-450%",
  transform: "rotate(-80deg)",
}));

export const Highlight3 = styled(Box)(({ theme }) => ({
  ...HighlightStyle,
  background:
    "radial-gradient(50% 50% at 50% 50%, #263fc5 0%, rgba(0, 0, 0, 0) 100%)",
  width: "20%",
  height: "700%",
  right: "10%",
  top: "-450%",
  transform: "rotate(80deg)",
}));
