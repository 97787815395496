import {
    Description,
    DescriptionContainer,
    SectionHeader,
  } from "components/Common/styled";
  
  import {
    Card,
    CardContainer,
    CardDesctiptionContainer,
    CardHead,
    CardImg,
    CardsContainer,
    MainContainer,
    WhiteText as WhiteTextSmall,
  } from "./styled";

  import checkMarkIcon from 'assets/checkmarkIcon.svg';
  import lightBulbIcon from 'assets/lightBulbIcon.svg';
  import EcosystemIcon from 'assets/EcosystemIcon.svg';
  import chainIcon from 'assets/chainIcon.svg';
  import riskMinimizeIcon from 'assets/riskMinimizeIcon.svg';
  import capitalIcon from 'assets/capitalIcon.svg';

  const DepositLiquidity = () => {
    return (
      <MainContainer>
        <DescriptionContainer>
          <SectionHeader>Deposit liquidity in $USDT, Earn rewards in $USDT</SectionHeader>
          <Description>
          Palmswap is the first perp DEX on BNB Chain with single asset liquidity pools. Every transaction on Palmswap happens in $USDT. You deposit liquidity in $USDT and earn rewards in $USDT, making it a sustainable and low-risk way to earn a yield on your assets.
          </Description>
        </DescriptionContainer>
        <CardsContainer>
          <CardContainer>
            <Card>
              <CardHead>
                <CardImg src={checkMarkIcon} />
                <WhiteTextSmall>Self-custodial</WhiteTextSmall>
              </CardHead>
              <CardDesctiptionContainer>
                <Description>
                    Palmswap V2 distributes 75% of the trading fee to LPs and stakers. This way, our platform ensures an attractive stream of income for you
                </Description>
              </CardDesctiptionContainer>
            </Card>
            <Card>
              <CardHead>
                <CardImg src={lightBulbIcon} />
                <WhiteTextSmall>So Simple</WhiteTextSmall>
              </CardHead>
              <CardDesctiptionContainer>
                <Description>
                    Providing liquidity on Palmswap is easier than making a coffee. Our friendly interface makes depositing and earning as easy as a few clicks
                </Description>
              </CardDesctiptionContainer>
            </Card>
            <Card>
              <CardHead>
                <CardImg src={EcosystemIcon} />
                <WhiteTextSmall>Ecosystem partner</WhiteTextSmall>
              </CardHead>
              <CardDesctiptionContainer>
                <Description>
                    Liquidity providers are like our partners to us. They receive a slice of every transaction on Palmswap V2 and grow with us as the platform grows
                </Description>
              </CardDesctiptionContainer>
            </Card>
          </CardContainer>
          <CardContainer>
            <Card>
              <CardHead>
                <CardImg src={chainIcon} />
                <WhiteTextSmall>No impermanent loss</WhiteTextSmall>
              </CardHead>
              <CardDesctiptionContainer>
                <Description>
                No exposure to complex liquidity ratios, slippages, and impermanent losses. Our high-quality Chainlink price feeds minimize losses for LPs even at the time of high fluctuations
                </Description>
              </CardDesctiptionContainer>
            </Card>
            <Card>
              <CardHead>
                <CardImg src={riskMinimizeIcon} />
                <WhiteTextSmall>Risk Minimized</WhiteTextSmall>
              </CardHead>
              <CardDesctiptionContainer>
                <Description>
                    Palmswap’s synthetic and smart architecture with dynamic algorithms reduces LP costs,  concentrates liquidity, and ceases unprofitability with minimum risk. 
                </Description>
              </CardDesctiptionContainer>
            </Card>
            <Card>
              <CardHead>
                <CardImg src={capitalIcon} />
                <WhiteTextSmall>Capital Efficientt</WhiteTextSmall>
              </CardHead>
              <CardDesctiptionContainer>
                <Description>
                    Palmswap’s single side liquidity pool doubles the trading efficiency on Palmswap v2 offering faster trading times and more volume resulting in more rewards for LPs.
                </Description>
              </CardDesctiptionContainer>
            </Card>
          </CardContainer>
        </CardsContainer>
      </MainContainer>
    );
  };
  
  export default DepositLiquidity