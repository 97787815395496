import React, { useState } from "react";

import {
  Description,
  DescriptionContainer,
  SectionHeader,
} from "components/Common/styled";
import {
  Card,
  CardContainer,
  CardDesctiptionContainer,
  CardsContainer,
  MainContainer,
  ChartContainer,
  Input,
  InputContainer,
  InputWrapper,
  InputCurrencyWrapper,
  InputCurrencyIconWrapper,
  InputCurrencyIcon,
  InputCurrencyText,
  ChartMainText,
  ChartTextBox,
  IntervalWrapper,
  IntervalOption,
  CalculateButton,
  Warning,
} from "./styled";

import tetherIcon from "assets/tetherIcon.svg";

import ChartSVG from "./ChartSVG";

const IntroducingPalmswapV2 = () => {
  const intervalOptions = [
    {
      name: "1 month",
      value: 1,
    },
    {
      name: "3 months",
      value: 3,
    },
    {
      name: "6 months",
      value: 6,
    },
    {
      name: "9 months",
      value: 9,
    },
    {
      name: "12 months",
      value: 12,
    },
  ];

  const intervalPositions = {
    "1 month": { cx: 55, cy: 244 },
    "3 months": { cx: 200, cy: 218 },
    "6 months": { cx: 350, cy: 192 },
    "9 months": { cx: 495, cy: 140 },
    "12 months": { cx: 645, cy: 13 },
  };

  const APR = 0.1;

  const [activeInterval, setActiveInterval] = useState("1 month");
  const [stakedAmount, setStakedAmount] = useState(20000);
  const [inputValue, setInputValue] = useState("20.000");

  const { cx, cy } = intervalPositions[activeInterval];

  const handleInputFocus = () => {
    setInputValue("");
  };

  const handleInputBlur = () => {
    if (inputValue === "") {
      setInputValue("0");
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
};

const handleCalculateClick = () => {
  let value = parseFloat(inputValue.replace(",", "."));
  if (isNaN(value) || value <= 0) {
    setInputValue("0");
    setStakedAmount(0);
  } else {
    setStakedAmount(value);
  }
};


  const calculateEarnings = () => {
    if (!stakedAmount || isNaN(stakedAmount)) {
      return 0;
    }

    const months = intervalOptions.find(
      (option) => option.name === activeInterval
    ).value;
    const monthlyReturn = stakedAmount * (APR / 12);
    return stakedAmount + monthlyReturn * months;
  };

  return (
    <MainContainer>
      <DescriptionContainer>
        <SectionHeader>Calculate your earnings</SectionHeader>
        <Description>
          Enter the amount of your deposit, and expected daily trading volume to
          learn your monthly and yearly APR.
        </Description>
      </DescriptionContainer>
      <CardsContainer>
         <CardContainer>
          <Card>
            <CardDesctiptionContainer>
              <Description>Select USDT provided amount</Description>
            </CardDesctiptionContainer>
            <InputWrapper>
              <InputContainer>
                <Input
                  value={inputValue}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  onChange={handleInputChange}
                />
                <Description>
                  ~${inputValue ? inputValue : "0.00"}
                </Description>
              </InputContainer>
              <InputCurrencyWrapper>
                <InputCurrencyIconWrapper>
                  <InputCurrencyIcon src={tetherIcon} />
                </InputCurrencyIconWrapper>
                <InputCurrencyText>USDT</InputCurrencyText>
              </InputCurrencyWrapper>
            </InputWrapper>

          </Card>
          <CalculateButton onClick={handleCalculateClick}>Calculate</CalculateButton>

          <Description>
             <Warning>
            (*Disclaimer)
            </Warning>
              Rewards may vary in the future based on trading fees and trading volume on the platform and are not guaranteed.
          </Description>
          </CardContainer> 


        <ChartContainer>
          <ChartTextBox>
              <Description>
                in{" "}
                {
                  intervalOptions.find(
                    (option) => option.name === activeInterval
                  ).name
                }{" "}
                you will have
              </Description>
              <ChartMainText>
                {calculateEarnings().toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                USDT
              </ChartMainText>
              <Description>
                ~$
                {calculateEarnings().toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Description>
            </ChartTextBox>
            <ChartSVG cx={cx} cy={cy} /> 

             <IntervalWrapper>
              {intervalOptions.map((option) => (
                <IntervalOption
                  key={option.value}
                  className={activeInterval === option.name ? "active" : ""}
                  onClick={() => setActiveInterval(option.name)}
                >
                  {option.name}
                </IntervalOption>
              ))}
            </IntervalWrapper> 
          </ChartContainer> 

      </CardsContainer>
    </MainContainer>
  );
};

export default IntroducingPalmswapV2;
