import {
  MainContainer,
} from "./styled";
 import CalculateEarnings from "./CalculateEarnings";
import LiquidityProgram from "./LiquidityProgram";
import DepositLiquidity from "./DepositLiquidity";
import Earn from './Earn'


const CenterSection = () => {

  return (
    <MainContainer>
      <CalculateEarnings />
      <LiquidityProgram/>
      <DepositLiquidity/>
      <Earn/>
    </MainContainer>
  )
}

export default CenterSection