import { ApolloClient, InMemoryCache } from "@apollo/client";

export function createSubgraph(subgraphUrl) {
  return new ApolloClient({
    uri: subgraphUrl,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
    },
  });
}


export function getPalmSubgraph() {
  const subgraphUrl = 'https://api.goldsky.com/api/public/project_cllqn805a7tva38uh4n5r9ap6/subgraphs/palmswap-synthetic-stats/mainnet/gn';
  return createSubgraph(subgraphUrl);
}
