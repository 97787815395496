import React, { useRef } from 'react';
import {
  Description,
  SectionHeader,
} from "components/Common/styled";
import LinkButton from "components/Common/LinkButton";

import {
  ImgCard,
  LeftSideContainer,
  MainContainer,
  RightSideContainer,
  TextContainer,
  VideoCard,
  CardContainer,
  MobileImgCard,
  CardContainerMobile
} from "./styled";

import liquidityImage from 'assets/Liquidity.png';
import leverageVideo from 'assets/Leverage_video.mp4';
import stakingRewardsImage from 'assets/Staking_rewards.png';
import exchangeMobileImage from 'assets/Mobile_exchange.png';
import leverageImage from 'assets/Leverage.png';
import { useMediaQuery } from "@reactuses/core";

const Advantages = () => {
  const isMobile = useMediaQuery(`(max-width: ${1000}px)`)

  const videoRef = useRef(null);

  const handleVideoHover = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <MainContainer>
      {!isMobile && (
         <RightSideContainer>
         <CardContainer>
          <VideoCard
              src={leverageVideo}
              autoPlay={false}
              onMouseEnter={handleVideoHover}
              ref={videoRef}
          />
        </CardContainer>
        <TextContainer>
          <SectionHeader>Up to 50x leverage to <br /> power up your trades</SectionHeader>
          <Description>
            Amplify your gains and multiply your trade’s potential with up to 50x leverage. Manage your risks effectively and implement your trading strategy with complete control over your positions. </Description>
          <LinkButton href="https://synthetic.palmswap.org" target='_blank'>Try Leverage Trading</LinkButton>
        </TextContainer>
      </RightSideContainer>
      )}

      {isMobile && (
      <LeftSideContainer>
         <TextContainer>
            <SectionHeader>Up to 50x leverage to <br /> power up your trades</SectionHeader>
            <Description>
                Amplify your gains and multiply your trade’s potential with up to 50x leverage. Manage your risks effectively and implement your trading strategy with complete control over your positions.            </Description>
             <LinkButton href="https://synthetic.palmswap.org" target='_blank'>Try Leverage Trading</LinkButton>
          </TextContainer>
           <CardContainer>
              <ImgCard src={leverageImage} />
            </CardContainer> 
          </LeftSideContainer>
      )}

      <LeftSideContainer>
        <TextContainer>
          <SectionHeader>Provide liquidity and earn <br /> rewards on every trade</SectionHeader>
          <Description>
            Deposit liquidity in USDT and start earning lucrative yields. Palmswap distributes 40% of the trading fee generated on the platform to liquidity providers and 30% to the ones who have staked $PALM.
          </Description>
          <LinkButton href="https://synthetic.palmswap.org/#/buy" target='_blank'>Add Liquidity</LinkButton>
        </TextContainer>
            <CardContainer>
          <ImgCard src={liquidityImage} />
        </CardContainer> 
      </LeftSideContainer>
      <RightSideContainer>
        <CardContainerMobile>
          <MobileImgCard src={stakingRewardsImage} style={{zIndex: '1'}}/>
          <MobileImgCard src={exchangeMobileImage} style={{marginTop: '40px', marginLeft: '-150px'}} />
        </CardContainerMobile>
        <TextContainer>
          <SectionHeader>Mobile optimized, trade <br /> perps on the go</SectionHeader>
          <Description>
            Never miss a trade again. Trade perpetuals anytime anywhere on your mobile with lightning speed and ease. Swiftly execute trades with a tap, capitalizing every market movement all from the convenience of your mobile device.
          </Description>
          <LinkButton href="https://synthetic.palmswap.org" target='_blank'>Start trading</LinkButton>
        </TextContainer>
      </RightSideContainer>
    </MainContainer>
  );
}

export default Advantages;
