import { Box, styled, Typography, TextField } from "@mui/material";
import LinkButton from "components/Common/LinkButton";
import { CardMuted } from "components/Common/styled";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: 70,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 50,
  },
}));


export const InputWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: 10,
}));


export const Warning = styled(Typography)(({ theme }) => ({
  color: 'red',
  fontSize: 14,
}));


export const InputCurrencyWrapper = styled(Box)(({ theme }) => ({
  width: 131,
  height: 50,
  display: "flex",
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: "row",
  gap: 10,
  padding: '4px 15px 4px 4px',

  background: 'rgba(255, 255, 255, 0.05);',
  borderRadius: 500,
}));

export const InputCurrencyIconWrapper = styled(Box)(({ theme }) => ({
  width: 44,
  height: 44,
  display: "flex",
  backgroundColor: '#50af95',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 500,
}));

export const InputCurrencyIcon = styled('img')(({ theme }) => ({
  width: 34,
  height: 30,
}));

export const InputCurrencyText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '16',
}));



export const InputContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 5,
}));



export const Input = styled(TextField)(({ theme }) => ({
  width: "100%",
  display: "flex",
  background: 'transparent',
  "&:hover": {
    borderColor: 'transparent',
    boxShadow: 'none',
    "& .MuiOutlinedInput-root": {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: 'transparent',
      }
    },
  },
  // Remove border when not hovering
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: 'transparent',
  },
    // Remove padding from the input element itself
    "& .MuiOutlinedInput-input": {
      padding: '0 !important',
      fontSize: '24px',
    },
  // Remove the blue focus border
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: 'transparent',
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));


export const CardImg = styled("img")(() => ({
  width: 35,
  height: 35,
  marginRight: 10,
}));


export const CardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: 20,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));


export const ChartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  position: 'relative',
  flexDirection: 'column',
  width: '100%',
  paddingTop: 20,
  gap: 10,
}));

export const ChartTextBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
}));

export const ChartMainText = styled(Typography)(() => ({
  fontSize: 24,
  color: '#00c075',
}));


export const LinkBtn = styled(LinkButton)(() => ({
  '&.MuiButtonBase-root': {
    minHeight: 40,
    height: 40,
  }
}));


export const WhiteText = styled(Typography)({
  fontSize: 18,
  color: colors.white,
});

export const Card = styled(CardMuted)(({theme}) => ({
  cursor: "pointer",
  height: 'fit-content',
  width: 400,
  display: "flex",
  gap: 20,
  flexDirection: "column",
  padding: 20,
  [theme.breakpoints.down("sm")]: {
    width: '100%',
  },
}));

export const CardHead = styled(Box)(() => ({
  display: 'flex',
  alignItems: "center",
}));

export const CardDesctiptionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
}));


export const IntervalWrapper = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "row",
  width: '100%',
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",

 
  },
}));


export const IntervalOption = styled("button")(({theme}) => ({
  width: 101,
  color: colors.inactive,
  height: 36,
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
  borderRadius: 500,
  fontSize: 16,
  '&.active': {
    color: 'white',
    background: 'rgba(255, 255, 255, 0.05)',
    border: '1px solid rgba(255, 255, 255, 0.06)',
  },

  [theme.breakpoints.down("sm")]: {
    width: 70,
    fontSize: 12,
  },
}));


export const CalculateButton = styled("button")(({theme}) => ({
  width: 400,
  color: 'white',
  height: 50,
  cursor: 'pointer',
  background: colors.gradientMain,
  border: 'none',
  borderRadius: 500,
  fontSize: 14,
  [theme.breakpoints.down("sm")]: {
    width: '100%',
  },
}));





