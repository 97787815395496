const ChartSVG = ({ cx, cy }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 686 285">
    <g>
      <defs>
        <linearGradient id="iduLnMn9Coag-1326729544" x1="0.49748743718592964" x2="0.5025125628140703" y1="1" y2="0">
          <stop offset="0" stop-color="rgba(9, 9, 11, 0)" stop-opacity="0"></stop>
          <stop offset="1" stop-color="rgb(106, 63, 233)" stop-opacity="1"></stop>
        </linearGradient>
      </defs>
      <path d="M 677.549 4.469 L 643.249 13.406 L 613.92 25.322 L 576.141 46.672 L 545.817 71.995 L 522.951 105.758 L 493.125 142.5 L 430.987 168.815 L 408.12 172.787 L 369.346 184.704 L 331.07 201.089 L 302.238 212.012 L 261.475 219.956 L 228.17 219.956 L 189.396 221.446 L 156.587 224.922 L 113.836 232.369 L -0 265.139 L 0.994 285.422 L 686 285.422 L 686 2.483 Z" fill="url(#iduLnMn9Coag-1326729544)"></path>
    </g>
    <g transform="translate(-5.965 2.483)">
      <path d="M -0 262.657 C -0 262.657 132.102 212.701 253.025 217.474 C 313.486 219.86 365.532 177.571 420.051 168.815 C 474.569 160.06 506.821 141.513 531.401 99.303 C 580.562 14.883 691.468 0 691.468 0" fill="transparent" stroke-width="2.94" stroke="rgb(106, 63, 233)" stroke-linecap="round" stroke-miterlimit="10"></path>
      <circle cx={cx} cy={cy} r="8" fill="rgb(106, 63, 233)"></circle>
    </g>
  </svg>
);

export default ChartSVG;
