import { Box, styled, Typography } from "@mui/material";
import { colors } from "config/colors";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

export const MoreIcon = styled(ExpandMoreRoundedIcon)({
  fontSize: 18,
  color: colors.white,
  cursor: "pointer",
});

export const LessIcon = styled(ExpandLessRoundedIcon)({
  fontSize: 18,
  color: colors.white,
  cursor: "pointer",
});

export const FooterContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px 200px 36px 200px",
  borderTop: `1px solid ${colors.border}`,
  maxWidth: 1550,
  marginLeft: "auto",
  marginRight: "auto",
  [theme.breakpoints.down("sm")]: {
    padding: "24px 16px 28px 16px",
  },
}));

export const LinksContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  flexDirection: "row",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));

export const Divider = styled(Box)({
  width: "100%",
  height: 1,
  background: colors.border,
  marginBottom: 26,
  marginTop: 30,
});

export const InfoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const MainLinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  width: 186,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    gap: 10,
  },
}));

export const Logo = styled("img")({
  width: 151,
  height: 31,
});

// TODO: delete any
const InactiveTextStyle: any = {
  fontSize: 12,
  color: colors.inactive,
  lineHeight: 1.5,
};

export const Description = styled(Typography)({
  ...InactiveTextStyle,
});

export const PolicyTermsContainer = styled(Box)({
  display: "flex",
  gap: 10,
});

export const PolicyTermsLink = styled("a")({
  ...InactiveTextStyle,
  cursor: "pointer",
  textDecoration: "underline",
});

export const LinkGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  width: "fit-content",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const LinkGroupNameContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const LinkGroupName = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  color: colors.white,
});

export const LinkGroupBody = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
});

export const LinkGroupBodyItem = styled("a")({
  ...InactiveTextStyle,
  textDecoration: "none",
  cursor: "pointer",
});
