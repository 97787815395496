import LinkButton from "components/Common/LinkButton";
import { Description, SectionHeader } from "components/Common/styled";
import { DescriptionContainerStyled, GridLines, MainContainer } from "./styled";
import lines from "assets/linesGrid.png";

const BottomSection = () => {
  return (
    <MainContainer>
      <DescriptionContainerStyled>
        <SectionHeader>Ready to dive in?</SectionHeader>
        <Description>Learn more about V2 in detail.</Description>
        <LinkButton href="https://docs.palmswap.org/" target="_blank">
          Documentation
        </LinkButton>
      </DescriptionContainerStyled>
      <GridLines src={lines} />
    </MainContainer>
  );
};

export default BottomSection;
