import { Box, styled } from "@mui/material";

export const HomePageContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}))

export const CenterBottom = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: 'center',
  maxWidth: 1550,
  marginLeft: "auto",
  marginRight: "auto"
}))

