import { Box, Drawer, IconButton, styled } from "@mui/material";
import { Close, Menu } from "@mui/icons-material";
import { colors } from "config/colors";

interface HeaderContainerProps {
  animate?: boolean;
}

export const HeaderContainer = styled(Box)<HeaderContainerProps>(
  ({ theme, animate }) => ({
    height: "max-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "fixed",
    zIndex: 3,
    top: "0",
    transition: animate ? "top 0.5s ease" : "",
    backdropFilter: "blur(5px)",
    borderBottom: `1px solid ${colors.border}`,
    [theme.breakpoints.down(1550)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  })
);

export const NavsContainer = styled(Box)(({ theme }) => ({
  height: "60px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: 1820,
  zIndex: 10,
  padding: "0px 15px",
  [theme.breakpoints.up(2000)]: {
    padding: "0px",
  },
  [theme.breakpoints.down(1120)]: {
    padding: "0px 15px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0px 10px",
  },
}));

export const HeaderLogo = styled("img")({
  cursor: "pointer",
  width: 151,
  height: 31,
});
export const LeftSide = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 20,
}));

export const RightSide = styled(Box)(() => ({
  height: "fit-content",
}));

export const MenuButton = styled(IconButton)(() => ({
  width: 35,
  height: 35,
  background: colors.border,
  zIndex: 1400,
}));

export const MenuIcon = styled(Menu)(() => ({
  pointerEvents: "none",
  fontSize: 15,
  color: colors.white,
}));

export const StyledDrawer = styled(Drawer)(() => ({
  "& .MuiPaper-root": {
    width: "100%",
    background: "#030303",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const TopMenuSection = styled(Box)(() => ({
  width: "100%",
  minHeight: 60,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 10px 0 20px",
  borderBottom: `1px solid ${colors.border}`,
}));

export const BodyMenuSection = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "24px",
  "&. MuiButton-root": {
    marginLeft: 16,
    marginRight: 16,
    width: "calc(100% - 32px)",
  },
}));

export const MobileMenuContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const CloseButton = styled(MenuButton)(() => ({
  width: 35,
  height: 35,
  background: colors.border,
}));

export const CloseIcon = styled(Close)(() => ({
  pointerEvents: "none",
  fontSize: 15,
  color: colors.white,
}));
