import React from "react"
import parse from 'html-react-parser';
import {
  Contents,
  ContentsTopSection,
  ContentsTopSectionText,
  ContentsTopSectionTitle,
  Description,
  MainContainer,
  NavItem, NavLetter,
  Navs,
  NavTitle,
  Term,
  TermParagraph,
  TermParagraphs,
  TermParagraphsNumber,
  TermParagraphsText,
  TermsContainer,
  TermsSection,
  TermTitle,
  Title,
} from "./styled"

const terms = [
  {
    number: '1',
    title: 'DUTIES OF THE REFERRING PARTY',
    paragraphs: [
      {
        paragraph: '1',
        text: 'Services. The Referring Party agrees to refer potential clients  to the Company’s website (which address is <a href="https://palmswap.org/">https://palmswap.org/</a>). In such capacity, the Referring Party may provide the Services.'
      },
    ]
  },
  {
    number: '2',
    title: 'FEE',
    paragraphs: [
      {
        paragraph: '1',
        text: `Fee. The Company may pay the Referring Party for the Services hereunder an amount of 20 - 40% out of the trading fees of clients who actually paid such trading fees for the Products (the “<strong>Client</strong>” and the  “<strong>Client Trading Fees</strong>”), and have been engaged in trading activities (the “<strong>Fee</strong>”). The Fee shall be due and payable, as provided in Section 10 within a period not exceeding a 30 days period from the Company’s receipt of the Client’s Client Trading Fees.`
      },
    ]
  },
  {
    number: '3',
    title: 'EXPENSES',
    paragraphs: [
      {
        paragraph: '1',
        text: `Each party hereunder shall bear its own expenses.`
      },
    ]
  },
  {
    number: '4',
    title: 'INDEPENDENT CONTRACTOR STATUS',
    paragraphs: [
      {
        paragraph: '1',
        text: `It is expressly understood and agreed that the Referring Party is and shall be an independent contractor and not an employee, joint venturer, or a partner of the Company with respect to the services contemplated by this Agreement. As an independent contractor, the Referring Party shall not be entitled to any pension, profit sharing, or similar or other benefits, if any, that are or may be available to employees of the Company. Further, the Referring Party shall pay any and all applicable taxes it may incur, of any nature whatsoever, including, without limitation, state, provisional and local taxes, imposed upon the fees paid to the Referring Party under this Agreement. The Referring Party shall have no authority to bind the Company to any contract, agreement or arrangement.`
      },
    ]
  },
  {
    number: '5',
    title: 'TERM AND TERMINATION',
    paragraphs: [
      {
        paragraph: '1',
        text: `Term. The term of this Agreement will commence as of the Effective Date and will continue until terminated by either party and for any reason, without notice.`
      },
      {
        paragraph: '2',
        text: `Survival of Provisions. The provisions of Section 6 (Compliance with Law), Section 8 (Disputes), Section 9 (Limitation of Liability) and Section 10 (Miscellaneous Provisions) shall survive the expiration or any earlier termination of this Agreement unless otherwise indicated.`
      },
    ]
  },
  {
    number: '6',
    title: 'COMPLIANCE WITH LAW',
    paragraphs: [
      {
        paragraph: '1',
        text: `Representations of referring party as of the effective date regarding compliance with law and sanctions.`
      },
      {
        paragraph: '2',
        text: `The Referring Party shall comply in all material respects with and perform his duties and obligations under this Agreement in compliance with all applicable laws, rules, or regulations in all material respects. Notwithstanding anything contained in this Agreement to the contrary, Referring Party shall make any disclosures, take any actions and/or refrain from taking any actions that are reasonably necessary for him/her to comply in all material respects with any applicable law, rule, or regulation, which will include, any applicable privacy or regulatory law or code and/or ethical standards which governs Referring Party’s activities. The Referring Party shall comply in all material respects with all policies of the Referring Party’s employer (if applicable), including any such policies requiring the Referring Party to disclose his financial relationship with the Company.`
      },
      {
        paragraph: '3',
        text: `Without derogating from the aforementioned, the Referring Party shall not, whatsoever, engage in misleading or deceptive advertising techniques, and shall clearly and conspicuously disclose that Referring Party receives a financial benefit for referring clients or potential clients to the Company.`
      },
    ]
  },
  {
    number: '7',
    title: 'NO CONSULTANT BENEFITS. PAYMENT IN CRYPTOCURRENCY; BY SMART CONTRACT',
    paragraphs: [
      {
        paragraph: '1',
        text: `The Referring Party hereby acknowledges that the Company may charge the Client Trading Fees in form of cryptocurrency, such as USDT (commonly known as a ‘<em>stablecoin</em>’ which is supposed to digitally and cryptographically represent a fiat U.S Dollar, or other cryptocurrencies, as the Company may decide upon, or otherwise change, alter and/or cease use thereby, in its sole discretion.`
      },
      {
        paragraph: '2',
        text: `The Referring Party hereby agrees to receive any and all Fees in the same cryptocurrency in which its referred Clients have paid the Client Trading Fees.`
      },
      {
        paragraph: '3',
        text: `The Referring Party hereby acknowledges that in order to claim the Fee, it shall dedicate a BEP20 compatible wallet address, which may interact with a smart contract designated by the Company, whereby the Referring Party shall be able to claim any and all Client Trading Fees.`
      },
      {
        paragraph: '4',
        text: `The Referring Party hereby acknowledges and further assumes all risks associated with the value of any cryptocurrency which it is entitled to receive with respect to this Agreement and within its framework, such risks include (but are not limited to) a de-pegging event of any ‘stablecoin’. It is hereby further acknowledged that the Company has no option, whatsoever, to affect the price of any and all such cryptocurrency.`
      },
    ]
  },
  {
    number: '8',
    title: 'DISPUTES',
    paragraphs: [
      {
        paragraph: '1',
        text: `The parties agree to effect all reasonable efforts to resolve any and all disputes between them in connection with this Agreement in an amicable manner through negotiation or mediation including at least two meetings involving the Referring Party and the authorized representative of the Company. To the extent this mediation process does not resolve the conflict, any dispute or claim arising out of or relating to this Agreement or the validity, interpretation, enforceability, or breach thereof, including any dispute over whether a provision of this Agreement has been breached, shall be subject to the jurisdiction of the British Virgin Islands, and jurisdiction is hereby conferred upon such court. Each party agrees to and consents to submit to the exclusive jurisdiction, venue and process of said court for all actions, suits or proceedings arising out of this Agreement.`
      },
    ]
  },
  {
    number: '9',
    title: 'LIMITATION OF LIABILITY',
    paragraphs: [
      {
        paragraph: '1',
        text: `TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE COMPANY OR ITS AFFILIATES BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS) HOWEVER CAUSED AND ON ANY LEGAL OR EQUITABLE THEORY OF LIABILITY, AND WHETHER OR NOT FOR BREACH OF CONTRACT, NEGLIGENCE OR OTHERWISE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS WILL APPLY NOTWITHSTANDING ANY BREACH OF CONDITION(S) OR FUNDAMENTAL TERM(S) OR FOR A FUNDAMENTAL BREACH (S). IN ANY CASE, THE COMPANY’S AND ITS AFFILIATE’S ENTIRE LIABILITY UNDER ANY PROVISION OF THIS AGREEMENT SHALL BE LIMITED TO AN AMOUNT OF US$ 100.00 (ONE HUNDRED U.S DOLLAR). THIS LIMITATION OF LIABILITY IS CUMULATIVE WITH ALL SUCH PARTY’S EXPENDITURES BEING AGGREGATED TO DETERMINE SATISFACTION OF THE LIMIT.`
      },
    ]
  },
  {
    number: '10',
    title: 'MISCELLANEOUS PROVISIONS',
    paragraphs: [
      {
        paragraph: '1',
        text: `<u>Notices</u>. Any notice, request, instruction or other documents to be given thereunder by any party hereto to the other party will be in writing and delivered by electronic mail transmission.`
      },
      {
        paragraph: '2',
        text: `<u>Non-waivers of Rights</u>. No failure or delay on the part of a party in exercising any right hereunder will operate as a waiver of, or impair, any such right. No single or partial exercise of any such right will preclude any other or further exercise thereof or the exercise of any other right. No waiver of any such right will be effective unless given in a signed writing. No waiver of any such right will be deemed a waiver of any other right hereunder.`
      },
      {
        paragraph: '3',
        text: `<u>Validity of Provisions; Severability</u>. If any provision of this Agreement is, or becomes, or is deemed, invalid, illegal, or unenforceable in any jurisdiction: (a) such provision will be deemed amended to conform to applicable laws of such jurisdiction so as to be valid and enforceable or, if it cannot be so amended without materially altering the intention of the parties, it will be stricken; (b) the validity, legality and enforceability of such provision will not in any way be affected or impaired thereby in any other jurisdiction; and (c) the remainder of this Agreement will remain in full force and effect.`
      },
      {
        paragraph: '4',
        text: `<u>Governing Law</u>. This Agreement will be governed by the laws of the British Virgin Islands as to validity, construction and performance without reference to principles of conflicts of laws.`
      },
      {
        paragraph: '5',
        text: `<u>Assignments</u>. Except for the permitted assignment as provided below, neither this Agreement nor any right or obligation arising hereunder may be assigned by any party hereto, in whole or in part, without the prior written consent of each other party hereto, which may be withheld in the absolute discretion of such other party. Any attempted assignment in violation of the terms hereof will be null and void and of no force or effect. Notwithstanding the aforementioned, the Company may assign its rights and delegate its duties hereunder to any entity which it controls, is controlled by or with which it is under common control. Subject to the foregoing sentence, this Agreement will be binding upon and inure to the benefit of the parties and their respective successors and assigns.`
      },
      {
        paragraph: '6',
        text: `<u>Entire Agreement; Amendments</u>. This Agreement and the exhibits hereto constitute the entire agreement among the parties relating to the subject matter hereof, and all prior negotiations, representations, agreements and understandings are superseded hereby. No agreements altering, amending or supplementing the terms hereof may be made except by means of a written document signed by the parties hereto.`
      },
      {
        paragraph: '7',
        text: `<u>Counterparts</u>. This Agreement may be executed in any number of counterparts each copy of which shall for all purposes be deemed an original. Facsimile or email PDF execution and delivery of this Agreement shall be legal, valid and binding execution and delivery for all purposes.`
      },
    ]
  },
]

const ReferralAgreementPage = () => {

  return (
    <MainContainer>
      <Title>REFERRAL AGREEMENT</Title>
      <Description>Last Updated: Tue May 4, 2023</Description>
      <TermsContainer>
        <Navs>
          {terms.map((nav, i) => (
            <NavItem key={nav.title + i} href={`/referral_agreement#${nav.number}`}>
              <NavLetter>{nav.number}.</NavLetter>
              <NavTitle>{nav.title}</NavTitle>
            </NavItem>
          ))}
        </Navs>
        <Contents>
          <ContentsTopSection>
            <ContentsTopSectionText>THIS REFERRAL AGREEMENT (this “<strong>Agreement</strong>”) governs the relationship between you (the “<strong>Referring Party</strong>”) as of the date you accept its terms (the “<strong>Effective Date</strong>”); and PALM LABS INC., a British Virgin Islands corporation (the “<strong>Company</strong>”).</ContentsTopSectionText>
            <ContentsTopSectionTitle>RECITALS</ContentsTopSectionTitle>
            <ContentsTopSectionText><strong>WHEREAS</strong>, the Company is engaged in, among other things, the development, marketing and the provision of a cryptocurrency trading platform, and;</ContentsTopSectionText>
            <ContentsTopSectionText><strong>WHEREAS</strong>, Company wishes to further conceive, develop and commercialize its products, which, for the purposes hereof shall be certain perpetual cryptocurrency trading contracts (the “<strong>Products</strong>”); and</ContentsTopSectionText>
            <ContentsTopSectionText><strong>WHEREAS</strong>, the Company and the Referring Party desire to enter into a referral arrangement whereby the Referring Party will refer, potential clients or clients for the Products to the Company upon the terms and conditions set forth below (the “<strong>Services</strong>”); and</ContentsTopSectionText>
            <ContentsTopSectionText><strong>WHEREAS</strong>, certain confidential, proprietary and trade secret information of the Company, including but not limited to, the Confidential Information as defined in Section 5.1 of this Agreement, may be disclosed to the Referring Party in connection with his or her performance of the Services; and</ContentsTopSectionText>
            <ContentsTopSectionText><strong>WHEREAS</strong>, the Company is willing to enter into this Agreement only if the Referring Party consents to the nondisclosure and other obligations set forth in Section 5 and Section 6 of this Agreement.</ContentsTopSectionText>
            <ContentsTopSectionTitle>AGREEMENT</ContentsTopSectionTitle>
            <ContentsTopSectionText><strong>NOW, THEREFORE</strong>, in consideration of the foregoing premises, the mutual covenants and agreements hereinafter contained and other good and valuable consideration, the receipt and sufficiency of which the Company and the Referring Party hereby acknowledge, the parties hereby agree as follows:</ContentsTopSectionText>
          </ContentsTopSection>
          <TermsSection>
            {terms.map((term, i) => (
              <Term key={term.title + i} id={`${term.number}`}>
                <TermTitle>{term.number}. {term.title}</TermTitle>
                <TermParagraphs>
                  {term.paragraphs.map((p, i) => (
                    <TermParagraph key={p.paragraph + i}>
                      <TermParagraphsNumber>{term.number}.{p.paragraph}.</TermParagraphsNumber>
                      <TermParagraphsText>{parse(p.text)}</TermParagraphsText>
                    </TermParagraph>
                  ))}
                </TermParagraphs>
              </Term>
            ))}
          </TermsSection>
        </Contents>
      </TermsContainer>
    </MainContainer>
  )
}

export default ReferralAgreementPage