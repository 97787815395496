import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "config/colors";
import CallMadeTwoToneIcon from "@mui/icons-material/CallMadeTwoTone";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

export const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: 170,
  padding: "0 150px",
  marginBottom: 70,
  gap: 120,
  [theme.breakpoints.down("sm")]: {
    padding: "0 16px",
    marginBottom: 12,
    flexDirection: "column",
    marginTop: 90,
    gap: 60,
  },
  [theme.breakpoints.up(2120)]: {
    justifyContent: "center",
  },
}));

export const LeftTop = styled(Box)(({ theme }) => ({
  width: 500,
  minWidth: 500,
  gap: 30,
  display: "flex",
  flexDirection: "column",
  zIndex: 2,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    minWidth: "100%",
    textAlign: "center",
  },
}));

export const RightBottom = styled(Box)(({ theme }) => ({
  height: 690,
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 36,
  fontWeight: "bold",
  color: colors.white,
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: colors.inactive,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",

  gap: 10,
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  width: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 12,
  overflow: "hidden",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const Card1 = styled(Box)(({ theme }) => ({
  width: 355,
  height: 110,
  background: colors.border,
  borderRadius: 25,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 22,
  padding: 16,
}));

export const Card2 = styled(Box)(({ theme }) => ({
  width: 355,
  height: 110,
  background: colors.border,
  borderRadius: 25,
  display: "flex",
  alignItems: "center",
  gap: 22,
  padding: 16,
}));

export const CardImg = styled("img")({
  width: 80,
  height: 80,
});

export const CardArrowIcon = styled(CallMadeTwoToneIcon)({
  fontSize: 20,
  color: colors.white,
});

export const CardFlexContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  textAlign: "start",
  textDecoration: "none",
});

export const CardWhiteText = styled(Typography)({
  fontSize: 16,
  fontWeight: "bold",
  color: colors.white,
  textDecoration: "none",
});

export const ClockIconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 4,
});

export const CardInactiveText = styled(Typography)({
  fontSize: 16,
  color: colors.inactive,
});

export const ClockIcon = styled(WatchLaterIcon)({
  fontSize: 16,
  color: colors.inactive,
});

export const CardPurpleText = styled(Typography)({
  fontSize: 12,
  color: colors.main,
  textDecoration: "underline",
});

export const CardSwitchContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 4,
});

export const CardSwitch = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }: { active?: boolean }) => ({
  cursor: "pointer",
  width: 10,
  height: 10,
  borderRadius: 5,
  background: active ? colors.white : colors.border,
}));

export const HighlightsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 0,
}));

export const ExchangeImg = styled("img")(({ theme }) => ({
  width: 575,
  position: "relative",
  left: 0,
  top: 0,
  zIndex: 2,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    borderRadius: 15,
  },
}));

// TODO: delete any
const HighlightStyle: any = {
  WebkitFilter: "blur(70px)",
  filter: "blur(70px)",
  zIndex: 1,
  position: "absolute",
  overflow: "hidden",
  transition: "filter .2s ease-in-out",
  willChange: "transform",
};

export const Highlight1 = styled(Box)(({ theme }) => ({
  ...HighlightStyle,
  background:
    "radial-gradient(50% 50% at 50% 50%, #352e69 0%, rgba(0, 0, 0, 0) 100%)",
  width: 730,
  height: 90,
  left: 20,
  top: -30,
}));

export const Highlight2 = styled(Box)(({ theme }) => ({
  ...HighlightStyle,
  background:
    "radial-gradient(50% 50% at 50% 50%, #401274 0%, rgba(0, 0, 0, 0) 100%)",
  width: 160,
  height: 630,
  left: -10,
  top: 50,
  [theme.breakpoints.down("sm")]: {
    width: 160,
    height: 160,
    left: -30,
    top: -30,
    WebkitFilter: "blur(80px)",
    filter: "blur(80px)",
  },
}));

export const Highlight3 = styled(Box)(({ theme }) => ({
  ...HighlightStyle,
  background:
    "radial-gradient(50% 50% at 50% 50%, #44447d 0%, rgba(0, 0, 0, 0) 100%)",
  width: 140,
  height: 510,
  left: 10,
  top: -30,
  [theme.breakpoints.down("sm")]: {
    width: 160,
    height: 160,
    right: -30,
    top: -30,
    WebkitFilter: "blur(80px)",
    filter: "blur(80px)",
  },
}));

export const Highlight4 = styled(Box)(({ theme }) => ({
  ...HighlightStyle,
  background:
    "radial-gradient(50% 50% at 50% 50%, #476f80 0%, rgba(0, 0, 0, 0) 100%)",
  width: 730,
  height: 90,
  left: 500,
  top: -30,
}));
