import React from "react";
import {
  MainContainer,
} from "./styled";
import Stats from './Stats'
import IntroducingPalmswapV2 from "./IntroducingPalmswapV2";
import Advantages from "./Advantages";
import Ecosystem from "./Ecosystem";
import EmailBox from "components/Common/EmailBox";

const CenterSection = () => {

  return (
    <MainContainer>
      <Stats />
      <IntroducingPalmswapV2 />
      <Advantages />
      <Ecosystem />
      <EmailBox />
    </MainContainer>
  )
}

export default CenterSection