import { Box, styled, Typography } from "@mui/material";
import { CardMuted } from "components/Common/styled";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 18,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: 18,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));

export const WhiteText = styled(Typography)({
  fontSize: 20,
  color: colors.white,
});



export const Card = styled(CardMuted)(() => ({
  cursor: "pointer",
  width: "100%",
  display: "flex",
  gap: 20,
  flexDirection: "column",
  padding: 20,

  "&:hover": {
    "& .MuiTypography-root": {
      color: colors.white,
    },
    "& .MuiButton-root": {
      color: colors.black,
      background: colors.white,
    },
    "& .MuiSvgIcon-root": {
      color: colors.black,
    },
    background: colors.purpleMuted,
  },
}));

export const CardHead = styled(Box)(() => ({
  display: 'flex',
  alignItems: "center",
}));

export const CardDesctiptionContainer = styled(Box)(() => ({
  height: '100%',
  gap: 15,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
}));

export const CardImg = styled("img")(() => ({
  width: 35,
  height: 35,
  marginRight: 10,
}));