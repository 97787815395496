import { Box, styled, Typography } from "@mui/material";
import LinkButton from "components/Common/LinkButton";
import { CardPurpleGradient } from "components/Common/styled";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));


export const CardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 900,
  gap: 18,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));


export const LinkBtn = styled(LinkButton)(() => ({
  '&.MuiButtonBase-root': {
    minHeight: 40,
    height: 40,
  }
}));


export const WhiteText = styled(Typography)({
  fontSize: 18,
  color: colors.white,
});

export const CardSection = styled(Box)(({theme}) => ({
  display: "flex",
  gap: 20,
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
  },
}));

export const Card = styled(CardPurpleGradient)(({theme}) => ({
  width: 370,
  height: 300,
  overflow: 'hidden',
  display: "flex",
  flexDirection: "column",
  position: 'relative',
  borderBottom: 'none',
  [theme.breakpoints.down("sm")]: {
    width: 350,
  },
}));


export const CardImg = styled("img")(({theme}) => ({
    width: 343,
    height: 332,
    position: 'absolute',
    [theme.breakpoints.down("sm")]: {
      width: 330,
      height: 320,
    },
  }));


export const CardMaintext = styled(Typography)(({theme}) => ({
    fontSize: 28,
    color: colors.white,
    fontWeight: 'bold',
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
}));

export const CardDesctiptionContainer = styled(Box)(() => ({
  gap: 15,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: 'center',
  textAlign: 'center'
}));
