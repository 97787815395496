import React from "react"
import parse from 'html-react-parser';
import {
  Contents,
  ContentsTopSection,
  ContentsTopSectionText,
  ContentsTopSectionTitle,
  Description,
  MainContainer,
  NavItem,
  Navs,
  NavTitle,
  Term,
  TermParagraph,
  TermParagraphs,
  TermParagraphsText,
  TermsContainer,
  TermsSection,
  TermTitle,
  Title,
} from "./styled"

const terms = [
  {
    title: 'Collecting Information',
    paragraphs: [
      {
        text: `Please note that the scope of this Privacy Policy is limited only to information collected by PALMSWAP through your use of its Services when applicable. Some information may be automatically collected, and some is collected when you interact with our Services. The type of information that may be automatically collected is non-personal information, which includes (if applicable) your session durations, the content you retained and/or accessed on the Services, the frequency and scope of your use of the Services and information about your computer and internet connection including the operating system you use and browser type.
        <br/><br/>
        Information from which you can be personally identified may also be collected, including but not limited to your name, organization you are attributed to and the location of the computer through which you used the Services (hereinafter: “<strong>Personal Information</strong>”). Personal Information shall be collected only if received voluntarily from you, including, without limitation, by registering to use our Platform, or if you use our Website. Information not to be collected and or mined will be the log of communications and the contents of the communications. All system logs are stored in secure dedicated areas with very limited permissions and active backups, preventing the possibility to edit or delete logs.
        <br/><br/>
        We will also collect the information uploaded on the Platform, as it enables you to manage documents you wish to issue and exchange. We will also collect your contact list which includes details on organizations you wish to exchange documents with. Please note that any Personal Information uploaded to the Platform by you, will be collected by PALMSWAP though it will not be used for any other purpose than providing you with our Services. 
        <br/><br/>
        Furthermore your payment information may be collected when you wish to use our Services. Such information may include your bank account information, date of birth, passport or national ID card, bank card statements and other information required to charge the applicable fees from you.
        <br/><br/>
        Such payment information would be provided directly to our third-party payment processors whose use of your Personal Information is governed by their own Privacy Policy. We take all the measures required so that your payment information will be securely processed by using contractual mechanisms to assure that our payment processor adheres to the standards set by PCI-DSS.`
      },
    ]
  },
  {
    title: 'How We Store and Transfer Information',
    paragraphs: [
      {
        text: `In order to provide our Services, manage and operate our business, we use third parties cloud services such as:
        <br/><br/>
        <ul>
          <li>
            <p>AWS</p>
          </li>
          <li>
            <p>In order to deliver our services and/or operate our business, Information, which may include Personal Data, may be processed by our third parties service providers ("<strong>Suppliers</strong>"). We transfer only the minimum data that is necessary for conducting our services. The data is transferred only to suppliers approved by us that allow compliance with GDPR.</p>
          </li>
        </ul>`
      }
    ]
  },
  {
    title: 'Information Security Efforts',
    paragraphs: [
      {
        text: `Palmswap invests significantly to protect the company and clients from malicious activity. This includes (but is not limited to) hardening operating systems according to best practices, endpoint protection, advanced network security including logging and detection capabilities, backup/ restore including high availability, encryption at rest and in transit, patch & vulnerability management, secure-software development lifecycle, automatic and manual security scans, incident response, compliance testing, secure deletion of information upon termination, secure identity and access management.`
      }
    ]
  },
  {
    title: 'How do we secure our software development process',
    paragraphs: [
      {
        text: 'Palmswap development process includes information security training alongside manual and automatic activity to identify and prevent information security vulnerabilities as early in the process as possible.'
      }
    ]
  },
  {
    title: 'Safeguarding services provided by peer cloud service provider',
    paragraphs: [
      {
        text: 'The Service provider ensures the level of security provided by the peer cloud service provider at all time.'
      }
    ]
  },
  {
    title: 'Termination',
    paragraphs: [
      {
        text: 'The Palmswap User’s termination process includes a short reversible retention period alongside performing secure deletion of the assets, the databases and the files dedicated to that customer. Palmswap customers can extract and backup their information including the logs regarding the activity in the system at any moment using the system.'
      }
    ]
  },
  {
    title: 'Use of Information',
    paragraphs: [
      {
        text: `We use the Personal Information we collect from you for a range of different business purposes according to different legal bases of processing. We may use or process your Personal Information for the following purposes. One or more purposes may apply simultaneously.
        <br/><br/>
        <ol>
          <li value="1">
            <p><strong>Providing the Requested Services</strong></p>
          </li>
        </ol>
        <ul>
          <li>
            <p>We collect Personal Information to provide you with the Services you contracted to receive when you wish to use our cloud based Platform.</p>
          </li>
          <li>
            <p>Such collection of information will enable us to provide you with technical and professional assistance, with regard to the Services you use or intend to use. </p>
          </li>
        </ul>
        <br/>
        We process the Personal Information where it is necessary for the adequate performance of the contract regarding the requested Services.
        <br/><br/>
        <ol>
          <li value="2">
            <p><strong>Improvement and Development of the Services</strong></p>
          </li>
        </ol>
        <ul>
          <li>
            <p>We collect Personal Information to improve and develop our Services and understand feedback on PALMSWAP's Services and to help provide more information on the use of those Services quickly and easily.</p>
          </li>
          <li>
            <p>We collect Personal Information for ongoing review and improvement of the information provided on our Website to ensure it is user friendly. </p>
          </li>
          <li>
            <p>We collect Personal Information to improve the management and administration of our business and maintain compliancy with our internal policies and procedures.</p>
          </li>
          <li>
            <p>We conduct surveys and research, test features in development, and analyze the information we have to evaluate and improve our Services, develop new features, and conduct audits and troubleshooting activities. </p>
          </li>
        </ul>
        <br/>
        We process this information in light of our legitimate interest in improving the Services, to allow our users to have the best experience.
        <br/><br/>
        <ol>
          <li value="3">
            <p><strong>Maintain a Safe and Secure Environment</strong></p>
          </li>
        </ol>
        <br/>
        We may use your information to detect and prevent fraud, abuse and security incidents in the following ways.
        <br/><br/>
        <ul>
          <li>
            <p>Verify and authenticate your identity and prevent unauthorized or illegal activity;</p>
          </li>
          <li>
            <p>Enhance the safety and security of our Services;</p>
          </li>
          <li>
            <p>Conduct security investigations and risk assessments;</p>
          </li>
          <li>
            <p>Prevent or take action against activities that are, or may be, in breach of our terms of service or applicable law. </p>
          </li>
        </ul>
        <br/>
        We process this information in light of our legitimate interest in improving our Services and enabling our users to browse in a secure environment.
        <br/><br/>
        <ol>
          <li value="4">
            <p><strong>Personalize Content, Advertising and Marketing</strong></p>
          </li>
        </ol>
        <ul>
          <li>
            <p>If you have used PALMSWAP&rsquo;s Services in the past, we have a legitimate business interest for matching the data we collect with other data we had already collected. </p>
          </li>
          <li>
            <p>This enables us to understand your needs and interests, optimize the content we send you and make it more suitable and relevant to your needs.</p>
          </li>
          <li>
            <p>This also enables us to improve your experience on the Services by providing you with personalized content, recommendations, and features.</p>
          </li>
        </ul>
        <br/>
        We process this information in light of our legitimate interest to personalize your experience and customize our content.`
      }
    ]
  },
  {
    title: 'Disclosure of Information and Transfer of Data',
    paragraphs: [
      {
        text: `Except as otherwise provided in this Privacy Policy, we reasonably attempt to ensure that we never intentionally disclose any of your Personal Information, to any third party without having received your permission, except as provided for herein or otherwise as permitted or required under law.
        <br/><br/>
        In order to perform our contractual and other legal responsibilities or purposes, we may, from time to time, need to share your Personal Information with third parties. We may as well share your Personal Information with our affiliates, subsidiaries or any third party service providers and individuals to facilitate our Services or any portion thereof, such as marketing, data management or storage services. We may also share your information with analytics service providers for analytics services. Such analytics service providers set their own cookies or other identifiers on your computer, through which they can collect information about your usage of our Website. This helps us compile aggregated statistics about the effectiveness of our Services. 
        <br/><br/>
        The above mentioned third parties may be located in countries other than your own, and we may send them information we receive. When such third party service providers process your Personal Information on our behalf, we will assure that they comply with obligations similar to those which are set forth in this Privacy Policy. We will also assure that they will abide by our data privacy and security requirements, and will be allowed to use the Personal Information solely for the purposes we set. We will transfer your Personal Information while using appropriate and suitable safeguards, while using a variety of legal mechanisms, including contracts, to ensure your rights and protections travel with your data.
        <br/><br/>
        We may also transfer your information, including Personal Information, in connection with a corporate merger, consolidation, the sale of related assets or corporate division or other fundamental corporate changes. Furthermore, information about you may also be released in order to comply with any valid legal obligation or inquiry or process such as a search warrant, subpoena, statute or court order. We will also release specific information in special cases, such as if you use the Services to perform an unlawful act or omission or take any act or omission that may damage PALMSWAP, its property and goodwill, or if there is an attempted breach of the security of the Services or a physical or property threat to you or others. You have the right to file a complaint to the relevant supervisory authority regarding any claim that may arise due to our data protection and privacy practices.`
      }
    ]
  },
  {
    title: 'Your Rights',
    paragraphs: [
      {
        text: `You have the right at any time to request to access or modify your information. To exercise these options, please contact us at office@palmswap.org. 
        <br/><br/>
        In some jurisdictions, in particular those located within the European Union (the "EU") or within the European Economic Area (the "EEA"), you may be afforded specific rights regarding your Personal Information. Subject to such eligibility, you may have the following rights to:
        <br/><br/>
        <ol>
          <li value="1">
            <p>Request a rectification of your Personal Information where the information we hold about you is incorrect or incomplete.</p>
          </li>
          <li>
            <p>Object to the processing of your Personal Information for direct marketing purposes.</p>
          </li>
          <li>
            <p>Object to the processing of your Personal Information where our legal basis for that processing is that such processing is necessary for our legitimate interests.</p>
          </li>
          <li>
            <p>Object to an automated decision-making (including profiling) in certain circumstances.</p>
          </li>
          <li>
            <p>Request the erasure of your Personal Information in certain circumstances, such as where processing is no longer necessary for the purpose it was originally collected for, and there is no compelling reason for us to continue to process or store it;</p>
          </li>
          <li>
            <p>Receive your Personal Information, or ask us to transfer it to another organization that you have provided to us, which we process by automated means, where our processing is either based on your consent or is necessary for the performance of a contract with you.</p>
          </li>
        </ol>
        <br/>
        Generally, with regard to information collected on our Website, PALMSWAP is a "Data Controller". Therefore, if you wish to exercise the above mentioned rights, please contact us, and we will make our best efforts to fulfill your request.
        <br/><br/>
        With respect to information collected via the Platform, PALMSWAP is a "Data Processor" and therefore if you wish to exercise the above mentioned rights, please contact the organization you are attributed to, and we will make our best efforts to assist in facilitating your request.
        <br/><br/>
        If you wish to file a request regarding any of the above, you may contact us at: office@palmswap.org`
      }
    ]
  },
  {
    title: 'California Online Privacy Protection Act',
    paragraphs: [
      {
        text: `<strong>THIS SECTION OF THIS PRIVACY POLICY DOES NOT CONSTITUTE ANY PERMISSION TO ACCESS THE WEBSITE AND/OR PLATFORM AND/OR SERVICES (EACH AS DEFINED IN OUR TERMS AND CONDITIONS), IF YOU RESIDE IN THE UNITED STATES</strong>
        <br/><br/>
        CalOPPA requires commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers, to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared. See more at: <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf.">http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf.</a>
        <br/><br/>
        According to CalOPPA, PALMSWAP agrees to the following:
        <br/><br/>
        <ul>
          <li>
            <p>Once this Privacy Policy is created, we will add a link to it on our home page or on the first significant page after entering the Services.</p>
          </li>
          <li>
            <p>PALMSWAP&rsquo;s Privacy Policy link includes the word 'Privacy' and can easily be found on each page on the Services. </p>
          </li>
          <li>
            <p>You can request to change your Personal Information by emailing us at: office@palmswap.org </p>
          </li>
        </ul>
        `
      }
    ]
  },
  {
    title: 'California Privacy Rights',
    paragraphs: [
      {
        text: `The California Consumer Privacy Act of 2018 (“CCPA”) permits users who are California residents to request to exercise certain rights. Subject to its applicability on PALMSWAP, and if you are a California resident, the CCPA grants you the right to request certain information about our practices with respect to your Personal Information. In particular, you can request to receive information on the following:
        <br/><br/>
        <ul>
          <li>
            <p>The categories and specific pieces of your Personal Information that we have collected.</p>
          </li>
          <li>
            <p>The categories of sources from which we collected your Personal Information.</p>
          </li>
          <li>
            <p>The business or commercial purposes for which we collected your Personal Information.</p>
          </li>
          <li>
            <p>The categories of third parties with which we shared your Personal Information.</p>
          </li>
        </ul>
        <br/>
        You can be rest assured that we do not sell your Personal Information. If you choose to exercise your rights, we will not charge you different prices or provide different quality of our Services, unless those differences are related to your provision of your Personal Information.
        <br/><br/>
        Please note that you must verify your identity and request before further action is taken. As a part of this process, government identification may be required. Moreover, you may designate an authorized agent to make a request on your behalf.
        <br/><br/>
        We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. Any disclosures we provide, will only cover the 12 month period preceding your verifiable request's receipt. If, for some reason, we cannot reply within such time frame, our response will include an explanation for our inability to comply. If you wish to exercise your CCPA rights, please contact us at: office@palmswap.org
        <br/><br/>
        We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:
        <br/><br/>
        <ol>
          <li>
            <p>Deny you goods or services.</p>
          </li>
          <li>
            <p>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</p>
          </li>
          <li>
            <p>Provide you with a different level or quality of goods or services.</p>
          </li>
          <li>
            <p>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</p>
          </li>
        </ol>
        `
      }
    ]
  },
  {
    title: 'CAN SPAM Act',
    paragraphs: [
      {
        text: `The CAN-SPAM Act is a Federal US law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out strict penalties for violations.
        <br/><br/>
        To be in accordance with CANSPAM, PALMSWAP agrees to the following:
        <br/><br/>
        <ul>
          <li>
            <p>Not use false or misleading subjects or email addresses.</p>
          </li>
          <li>
            <p>Identify the commercial message sent to you as an advertisement when required. </p>
          </li>
          <li>
            <p>Include the physical address of our business or site headquarters.</p>
          </li>
          <li>
            <p>Monitor third-party email marketing services for compliance, if one is used.</p>
          </li>
          <li>
            <p>Honor opt-out/unsubscribe requests quickly.</p>
          </li>
          <li>
            <p>Allow users to unsubscribe by using the link at the bottom of each email.</p>
          </li>
        </ul>
        <br/>
        If at any time you would like to unsubscribe from receiving future emails, you can email us at office@palmswap.org and we will promptly remove you from ALL correspondence.
        `
      }
    ]
  },
  {
    title: 'Cookies',
    paragraphs: [
      {
        text: `We may use “cookies” and/or other technologies or files (collectively, “<strong>cookies</strong>”) to identify how users make use of our Services. This aggregated tracking information may be used to help us improve and enhance the Services’ experience for all of our users. In addition, cookies are used for adjusting the Services to your personal preferences. Cookies contain information such as the pages you visited, the length of time you stayed on the Services, the location from which you accessed the Services and more. If you would prefer not to have cookies stored on your computer, you may modify your browser settings to reject most cookies, or manually remove cookies that have been placed on your computer. However, by rejecting the cookies, you may be unable to fully access the offerings on our Services. To find out more about cookies, visit <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>
        <br/><br/>
        For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.`
      }
    ]
  },
  {
    title: 'Opt In or Opt Out',
    paragraphs: [
      {
        text: `You are always in control of your data, and if you choose to receive information from us, or others, you can change your mind later. If at any time, you would like to stop receiving such information or opt out of a feature, you may notify us by writing to office@palmswap.org 
        You should be aware, however, that it is not always possible to completely remove or modify information in our databases and servers, although we will always make reasonable efforts to do so upon your request.`
      }
    ]
  },
  {
    title: 'Links to Other Websites',
    paragraphs: [
      {
        text: `The Services may provide links to other websites. Please be aware that these other websites are not covered by our Privacy Policy. This Privacy Policy does not cover the information practices exercised by other providers of products or services, advertisers or other websites, companies or individuals, which are not owned or controlled by PALMSWAP. We suggest that when linking to another website, you always read that website’s privacy policy before volunteering any personally identifiable information.`
      }
    ]
  },
  {
    title: 'Data Security',
    paragraphs: [
      {
        text: `We deploy industry standard measures to ensure the security, confidentiality, integrity and availability of the Personal Information we process. We maintain physical, technical and administrative safeguards, and test and update these periodically. We endeavor to restrict access to Personal Information on a ‘need to know’ basis for the provision of Services to you. No such measures are perfect or impenetrable. In the event of a security breach, we will take all reasonable action to minimize any harm. Although we will do our best to protect Personal Information, we cannot guarantee the security of data transmitted to our Services and transmission is <strong>at the users’ own risk.</strong>`
      }
    ]
  },
  {
    title: 'Time Zone',
    paragraphs: [
      {
        text: `The platform's time zone is UTC.`
      }
    ]
  },
  {
    title: 'Data Retention',
    paragraphs: [
      {
        text: `Generally, PALMSWAP does not retain information longer than necessary to provide its Services and for its reasonable business and lawful needs. If you withdraw your consent to us processing your Personal Information, we will erase your Personal Information from our systems, unless the Personal Information is required for PALMSWAP to establish, exercise or defend against legal claims or it is necessary for the performance of the requested Services.`
      }
    ]
  },
  {
    title: "Children's Privacy",
    paragraphs: [
      {
        text: `The Service is not intended for children under the age of 16. We do not, knowingly or intentionally, collect information about children who are under 16 years of age.
        <br/><br/>
        <strongIF YOU ARE UNDER THE AGE OF 16 YOU MAY NOT USE THE SERVICE, UNLESS PARENTAL CONSENT IS PROVIDED ACCORDINGLY><strong/>`
      }
    ]
  },
  {
    title: 'Questions Regarding Our Privacy Policy',
    paragraphs: [
      {
        text: `If you have any questions regarding this Privacy Policy or the practices described above, you are always welcome to contact us at office@palmswap.org.`
      }
    ]
  },
  {
    title: 'Revisions and Modifications to our Privacy Policy',
    paragraphs: [
      {
        text: `We reserve the right to revise, amend, or modify this Privacy Policy at any time. When changing the policy, we will update this posting accordingly. Please review this Privacy Policy often so that you will remain updated regarding our current policies.`
      }
    ]
  },
  {
    title: 'Governing Law and Jurisdiction',
    paragraphs: [
      {
        text: `This Privacy Policy will be governed and interpreted pursuant to the laws of British Virgin Islands, without giving effect to its choice of law rules. You expressly agree that the exclusive jurisdiction for any claim or action arising out of or relating to this Privacy Policy shall be to the competent courts in England, to the exclusion of any other jurisdiction.`
      }
    ]
  },
]

const PrivacyPolicyPage = () => {

  return (
    <MainContainer>
      <Title>Palmswap Privacy Policy</Title>
      <Description>Last Updated: 19st of September 2022.</Description>
      <TermsContainer>
        <Navs>
          {terms.map((nav, i) => (
            <NavItem key={nav.title + i} href={`/privacy#${nav.title}`}>
              <NavTitle>{nav.title}</NavTitle>
            </NavItem>
          ))}
        </Navs>
        <Contents>
          <ContentsTopSection>
            <ContentsTopSectionTitle>Palm Labs Inc. (“<strong>PALMSWAP</strong>”, “<strong>we</strong>”, “<strong>us</strong>”, “<strong>our</strong>”) is committed to maintaining the privacy of its users (“<strong>user</strong>”, “<strong>you</strong>”). The following information describes how PALMSWAP collects and processes information about you when you use our Services (as defined below).</ContentsTopSectionTitle>
            <ContentsTopSectionText>
              <p>Our Privacy Policy explains:</p>
              <ul>
                <li>
                  <p>What information we collect and why we collect it.</p>
                </li>
                <li>
                  <p>How we use that information.</p>
                </li>
                <li>
                  <p>Your rights with regard to the collection of such information.</p>
                </li>
              </ul>
            </ContentsTopSectionText>
            <ContentsTopSectionText>By using our Services, you agree to this Privacy Policy.</ContentsTopSectionText>
            <ContentsTopSectionText>PALMSWAP owns and develops <a href="https://palmswap.org/">https://palmswap.org/</a> (“<strong>Palmswap</strong>”) which is a decentralized exchange for perpetual trading.
            </ContentsTopSectionText>
          </ContentsTopSection>
          <TermsSection>
            {terms.map((term, i) => (
              <Term key={term.title + i} id={term.title}>
                <TermTitle>{term.title}</TermTitle>
                <TermParagraphs>
                  {term.paragraphs.map((p, i) => (
                    <TermParagraph key={i}>
                      <TermParagraphsText>{parse(p.text)}</TermParagraphsText>
                    </TermParagraph>
                  ))}
                </TermParagraphs>
              </Term>
            ))}
          </TermsSection>
        </Contents>
      </TermsContainer>
    </MainContainer>
  )
}

export default PrivacyPolicyPage