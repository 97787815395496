import logo from "assets/logo.svg";
import TradeNowButton from "components/TradeNowButton";
import { footerMenu2 as linkGroups } from "config/footerMenu";
import { useState } from "react";
import { useMediaQuery, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  Description,
  Divider,
  FooterContainer,
  InfoContainer,
  LessIcon,
  LinkGroup,
  LinkGroupBody,
  LinkGroupBodyItem,
  LinkGroupName,
  LinkGroupNameContainer,
  LinksContainer,
  Logo,
  MainLinkContainer,
  MoreIcon,
  PolicyTermsContainer,
  PolicyTermsLink,
} from "./styled";
import { useNavigate } from "react-router-dom";

const FooterMenu = ({ linkGroup }: { linkGroup: (typeof linkGroups)[0] }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <LinkGroup>
      <LinkGroupNameContainer onClick={() => setOpen(!open)}>
        <LinkGroupName>{linkGroup.name}</LinkGroupName>
        {!open && isSmallScreen && <MoreIcon />}
        {open && isSmallScreen && <LessIcon />}
      </LinkGroupNameContainer>
      {(open || !isSmallScreen) && (
        <LinkGroupBody>
          {linkGroup.links.map((link, i) =>
            link.link === "#" ? (
              <Tooltip
                key={link.name + i + 'tooltip'}
                title="Coming soon"
                arrow
                style={{ fontSize: 12, color: "#6C7284", fontWeight: "500" }}
              >
                <Typography
                  key={link.name + i}
                  component={LinkGroupBodyItem}
                  href={link.link}
                  sx={{
                    fontSize: isSmallScreen ? "13px" : "16px",
                    fontWeight: "300",
                    color: "#787878",
                    ":hover": {
                      opacity: 1,
                      color: "#6f57d1",
                    },
                  }}
                >
                  {link.name}
                </Typography>
              </Tooltip>
            ) : (
              <LinkGroupBodyItem
                key={link.name + i}
                href={link.link}
                target="_blank"
              >
                {link.name}
              </LinkGroupBodyItem>
            )
          )}
        </LinkGroupBody>
      )}
    </LinkGroup>
  );
};

const Footer = function () {
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <LinksContainer>
        <MainLinkContainer>
          <Logo src={logo} alt="palmLogo" />
          <Description>
            Trade perpetuals decentralized with up to 50x leverage and earn
            tokens while trading through trading epoches.
          </Description>
          <TradeNowButton styles={null} />
        </MainLinkContainer>
        {linkGroups.map((linkGroup, i) => (
          <FooterMenu key={linkGroup.name + i} linkGroup={linkGroup} />
        ))}
      </LinksContainer>
      <Divider />
      <InfoContainer>
        <Description>
          Copyright © 2023 Palmswap. All rights reserved.
        </Description>
        <PolicyTermsContainer>
          <PolicyTermsLink onClick={() => navigate("/privacy")}>
            Privacy Policy
          </PolicyTermsLink>
          <PolicyTermsLink onClick={() => navigate("/terms_of_use")}>
            Terms of Service
          </PolicyTermsLink>
        </PolicyTermsContainer>
      </InfoContainer>
    </FooterContainer>
  );
};

export default Footer;
