import React from "react";
import LinkButton from "components/Common/LinkButton";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@reactuses/core";

import {
  ButtonsContainer,
  Description,
  ExchangeImg,
  Highlight1,
  Highlight2,
  Highlight3,
  Highlight4,
  HighlightsContainer,
  LeftTop,
  MainContainer,
  RightBottom,
  Title,
  CardContainer,
  Card1,
  CardImg,
  CardFlexContainer,
  CardWhiteText,
  CardWhiteTextSmall,

} from "./styled";
import exchangeImage from "assets/ExchangeImage.png";

import bannerImage from "assets/bannerImage.png";

import styled from "@emotion/styled";

export const StyledLink = styled(Link)({
  textDecoration: "none",
});

const TopSection = () => {
  const isDesktop = useMediaQuery(`(min-width: ${1000}px)`)

  return (
    <MainContainer>
      <LeftTop>
        <Title>
          Perpetual trading,
          <br />
          decentralized and simplified
        </Title>
        <Description>
          Trade perpetual futures with up to 50x leverage, deep liquidity, and
          low fees directly from your wallet.
        </Description>
        <ButtonsContainer>
          <LinkButton href="https://synthetic.palmswap.org" target="_blank">
            Trade Now
          </LinkButton>
          <LinkButton
            href="https://docs.palmswap.org/"
            color="secondary"
            target="_blank"
          >
            Docs
          </LinkButton>
        </ButtonsContainer>
        {isDesktop &&(
          <CardContainer>
          <StyledLink to="http://synthetic.palmswap.org/#/earn" target="_blank">
            <Card1>
              <CardImg src={bannerImage} alt='banner'/>
              <CardFlexContainer>
                <CardWhiteText>Earn up to 40% <br/> of Trading fees</CardWhiteText>
                <CardWhiteTextSmall>Add Liquidity to earn Trading fees and a range of other protocol revenues. </CardWhiteTextSmall>
              </CardFlexContainer>
            </Card1>
          </StyledLink>
        </CardContainer>
        )}
      </LeftTop>
      <RightBottom>
        <ExchangeImg src={exchangeImage} alt="exchangeScreen" />
        <HighlightsContainer>
          <Highlight1 />
          <Highlight2 />
          <Highlight3 />
          <Highlight4 />
        </HighlightsContainer>
      </RightBottom>
    </MainContainer>
  );
};

export default TopSection;
