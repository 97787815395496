import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldProps } from "@mui/material";

import {
  SectionHeader,
  StatusSuccess,
  StyledErrorText,
  StyledFormControl,
  SuccessButton,
  SuccessButtonIcon,
  Description,
  SuccessText,
} from "components/Common/styled";
import {
  EmailBoxContainer,
  EmailDescription,
  EmailInput,
  EmailInputContainer,
  InputButton,
  InputButtonIcon,
  InputContainer,
} from "./styled";

import {
  Modal,
  ModalContainer,
  ModalHeader,
  CloseButton,
  CloseIcon,
} from "../EventPage/CenterSection/EarlyAccessApplication/styled";

import { EMAIL_REG_EXP } from "../../utils/yup-validations";
import { getParentForm } from "../WeberForms";
import { FORM_TYPES } from "../WeberForms/interface";

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("Please enter your email")
      .matches(EMAIL_REG_EXP, "Not a valid email"),
  })
  .required();

const INPUT_WEBER_NAMES = {
  email: "email",
};

const EmailBox = () => {
  const [nextStep, setNextStep] = useState(false);
  const [show, setShow] = useState(false);

  const {
    formState: { errors, isValid },
    handleSubmit: formSubmit,
    setValue,
    getValues,
  } = useForm({
    defaultValues: { email: "" },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { email } = getValues();

  const handleChange: TextFieldProps["onChange"] = (evt) => {
    const { value, name } = evt.target as {
      name: keyof typeof INPUT_WEBER_NAMES;
      value: string;
    };

    setValue(name, value, { shouldValidate: true });

    const form = getParentForm(FORM_TYPES.SUBSCRIBE);
    const weberInputName = INPUT_WEBER_NAMES[name];
    const input = form.querySelector(`input[name="${weberInputName}"]`);

    if (input) input.setAttribute("value", value);
  };

  const handleSubmit = formSubmit((data, e) => {
    e.preventDefault();
    const form = getParentForm(FORM_TYPES.SUBSCRIBE);
    const button = form.querySelector(".submit");
    setShow(true);
    setNextStep(true);

    if (button instanceof HTMLElement) {
      button.click();
    }
  });

  return (
    <EmailBoxContainer>
      {show && (
        <ModalContainer onClick={() => setShow(false)}>
          <Modal>
            <ModalHeader>
              Almost finished, you need to confirm your email address
            </ModalHeader>
            <Description>
              We need to confirm your email address.To complete the subscription
              process, please click the link in the email we just sent you.
            </Description>
            <CloseButton onClick={() => setShow(false)}>
              <CloseIcon />
            </CloseButton>
          </Modal>
        </ModalContainer>
      )}
      <SectionHeader>
        Sign up for the
        <br />
        latest news
      </SectionHeader>
      <EmailInputContainer>
        <EmailDescription>
          Get Palmswap Exchange info before anyone else by subscribing to our
          exclusive newsletter.
        </EmailDescription>

        <InputContainer>
          {!nextStep ? (
            <>
              <StyledFormControl>
                <EmailInput
                  variant="outlined"
                  placeholder="Enter email"
                  type={"email"}
                  value={email}
                  name="email"
                  onChange={handleChange}
                />
                {!isValid && (
                  <StyledErrorText>{errors.email?.message}</StyledErrorText>
                )}
              </StyledFormControl>
              <InputButton onClick={handleSubmit}>
                <InputButtonIcon />
              </InputButton>
            </>
          ) : (
            <StatusSuccess>
              <SuccessButton
                disabled={!isValid}
                onClick={() => setNextStep(false)}
              >
                <SuccessButtonIcon />
              </SuccessButton>
              <SuccessText>You're subscribed.</SuccessText>
            </StatusSuccess>
          )}
        </InputContainer>
      </EmailInputContainer>
    </EmailBoxContainer>
  );
};

export default EmailBox;
