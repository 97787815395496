import {
    Description,
    DescriptionContainer,
    SectionHeader,
  } from "components/Common/styled";
  import {
    Card,
    CardContainer,
    CardDesctiptionContainer,
    CardImg,
    MainContainer,
    CardSection,
    CardMaintext,
  } from "./styled";
  
  
  import connectWallet from 'assets/ConnectWallet.png';
  import addLiquidity from 'assets/AddLiquidity.png';
  import confirmTransaction from 'assets/ConfirmTransaction.png';  

  
  const LiquidityProgram = () => {
  
    return (
      <MainContainer>
        <DescriptionContainer>
          <SectionHeader>Palmswap Liquidity Program: Built for all to earn</SectionHeader>
          <Description>
            Whether you are a native or new to DeFi, Palmswap’s single-sided liquidity pools provide the most direct and simplest pathway to deposit and earn passive income.
            You can deposit and withdraw funds anytime while you earn rewards daily. 
        </Description>
        </DescriptionContainer>
          <CardContainer>
            <CardSection>
                <Card>
                 <CardImg src={connectWallet} style={{ top: 13, left: "50%", transform: "translateX(-50%)", }}/>
                </Card>
                <CardDesctiptionContainer>
                    <CardMaintext>
                        1. Connect Wallet
                    </CardMaintext>
                    <Description>
                        Link your digital wallet to the platform.
                    </Description>
                </CardDesctiptionContainer>
            </CardSection>
            <CardSection>
            <Card>
                <CardImg src={addLiquidity} style={{ top: 13, left: 13, width: '450px' }}/>
            </Card>

            <CardDesctiptionContainer>
                <CardMaintext>
                    2. Add Liquidity
                </CardMaintext>
                <Description>
                    Deposit assets to the liquidity pool.
                </Description>
            </CardDesctiptionContainer>
            </CardSection>
            <CardSection>
             <Card>
                 <CardImg src={confirmTransaction} style={{ top: 13, left: "50%", transform: "translateX(-50%)", }}/>
             </Card>
 
             <CardDesctiptionContainer>
                <CardMaintext>
                    3. Confirm transaction
                </CardMaintext>
                <Description>
                    Validate and authorize the transaction.
                </Description>
            </CardDesctiptionContainer>
            </CardSection>
          </CardContainer>
      </MainContainer>
    );
  };
  
  export default LiquidityProgram