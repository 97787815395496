export const footerMenu = {
  About: [
    { Documentation: "https://docs.palmswap.org/" },
    { Blog: "https://blog.palmswap.org" },
    { "FAQ's": "/faq" },
    { "Brand Guidelines": "/guidelines" },
    { Feedback: "https://palmswap.canny.io/" },
    { "Privacy Policy": "/privacy" },
    { "Referral Agreement": "/referral_agreement" },
    { "Terms of Use": "/terms_of_use" },
  ],
  Developers: [
    { Github: "#" },
    { "Smart Contracts": "#" },
    { "Bug Bounty": "#" },
  ],
  Careers: [
    { "Job Board": "https://angel.co/company/palmswap-1/jobs" },
    { "Write Us": "mailto:office@palmswap.org" },
  ],
  "Social Network": [
    { Discord: "https://discord.com/invite/MQeV5yr3sy" },
    { Medium: "https://medium.com/@Palmswap" },
    { Twitter: "https://twitter.com/Palmswaporg" },
    { "Telegram Chat": "https://t.me/Palmswapchat" },
    { "Telegram Ann": "https://t.me/PalmswapAnn" },
  ],
};

export const footerMenu2 = [
  {
    name: "Services",
    links: [
      {
        name: "Trade",
        link: "https://synthetic.palmswap.org/#/buy",
      },
      {
        name: "Events",
        link: "/event",
      },
      {
        name: "Earn",
        link: "#",
      },
      {
        name: "Add Liquidity",
        link: "#",
      },
    ],
  },
  {
    name: "Company",
    links: [
      {
        name: "Launch App",
        link: "https://synthetic.palmswap.org",
      },
      {
        name: "Blog",
        link: "https://blog.palmswap.org",
      },
      {
        name: "Academy",
        link: "https://academy.palmswap.org",
      },
      {
        name: "Careers",
        link: "https://angel.co/company/palmswap-1/jobs",
      },
      {
        name: "Contact",
        link: "mailto:office@palmswap.org",
      },
    ],
  },
  {
    name: "Help",
    links: [
      {
        name: "Roadmap",
        link: "https://docs.palmswap.org/roadmap",
      },
      {
        name: "FAQ",
        link: "https://docs.palmswap.org/faq",
      },
      {
        name: "Telegram Support",
        link: "https://t.me/Palmsupport",
      },
    ],
  },
  {
    name: "Tokens",
    links: [
      {
        name: "Buy PLP",
        link: "#",
      },
      {
        name: "Buy PALM",
        link: "https://pancakeswap.finance/swap?outputCurrency=0x29745314B4D294B7C77cDB411B8AAa95923aae38",
      },
    ],
  },
  {
    name: "Resources",
    links: [
      {
        name: "Privacy Policy",
        link: "/privacy",
      },
      {
        name: "Terms of Service",
        link: "/terms_of_use",
      },
    ],
  },
];
