import {
  Description,
  DescriptionContainer,
  SectionHeader,
} from "components/Common/styled";
import {
  Card,
  CardContainer,
  CardDesctiptionContainer,
  CardHead,
  CardImg,
  CardsContainer,
  MainContainer,
  TokenCard,
  TokenCardRow,
  TokenCardTop,
  TokenImg,
  TokenLink,
  TokenName,
  TokenPrice,
  TokenStats,
  TokenStatContainer,
  InfoCardContainer,
  WhiteText as WhiteTextSmall,
} from "./styled";

import { useTokenInfo } from "api/useTokenInfo";
import { formatAmount } from "api/formatAmount";

import ethIcon from "assets/eth.svg";
import btcIcon from "assets/bitcoin.svg";
import bnbIcon from "assets/bnb.svg";
import xrpIcon from "assets/xrp.svg";
import linkIcon from "assets/link.svg";
import solIcon from "assets/sol.svg";
import maticIcon from "assets/matic.svg";
import avaxIcon from "assets/avax.svg";
import dogeIcon from "assets/doge.svg";

import walletIcon from "assets/Wallet.png";
import lightningIcon from "assets/Lightning.png";
import zeroIcon from "assets/Zero.png";
import { useHourlyVolumeByToken } from "api/TradingInfo";
import { useMemo } from "react";
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";

type FormattedTokenInfo = {
  name: string;
  symbol: string;
  img: string;
  price: string;
  change: string;
  volume24h: string;
};

const formatVolume = (_volume?: BigNumberish) => {
  if (!_volume) {
    return roundToDisplay(0);
  }
  const volume = BigNumber.from(_volume);
  const formattedAmount = formatAmount(volume, 30, 0, true);
  const cleanedAmountString = formattedAmount.replace(/[^0-9.]/g, "");
  const parsedAmount = parseFloat(cleanedAmountString);
  const formattedVolume = roundToDisplay(parsedAmount);

  return formattedVolume;
};

function roundToDisplay(value: number) {
  if (value >= 1e9) {
    const valueInB = (value / 1e9).toFixed(2);
    return valueInB + "B";
  }
  if (value >= 1e6) {
    const valueInM = (value / 1e6).toFixed(2);
    return valueInM + "M";
  }
  if (value >= 1e3) {
    const valueInK = (value / 1e3).toFixed(2);
    return valueInK + "K";
  }
  return value.toFixed(2);
}

const IntroducingPalmswapV2 = () => {
  const BTCData = useTokenInfo("BTC");
  const ETHData = useTokenInfo("ETH");
  const BNBData = useTokenInfo("BNB");
  const XRPData = useTokenInfo("XRP");
  const LINKData = useTokenInfo("LINK");
  const SOLData = useTokenInfo("SOL");
  const MATICData = useTokenInfo("MATIC");
  const AVAXData = useTokenInfo("AVAX");
  const DOGEData = useTokenInfo("DOGE");

  const BTC = "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c";
  const ETH = "0x2170ed0880ac9a755fd29b2688956bd959f933f8";
  const BNB = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  const XRP = "0xAeeff2e9388A578C02754Cd08699652fB76035C7";
  const LINK = "0x877414523e7fbCA00C211bc787aeAe69e2e3A779";
  const SOL = "0xe9B2bD4742a785464053aA23AFAd8bd24650e86F";
  const MATIC = "0xA73188d808fc1F3cf5743FADB68A02ED884B594f";
  const AVEX = "0x7fCe309E384d008D4BC14C846dCCE9E31050E29B";
  const DOGE = "0x8379E78C3a0756495A0F585D0b3c84a9E9718F9d";

  const BTCVolume = useHourlyVolumeByToken(BTC);
  const ETHVolume = useHourlyVolumeByToken(ETH);
  const BNBVolume = useHourlyVolumeByToken(BNB);
  const XRPVolume = useHourlyVolumeByToken(XRP);
  const LINKVolume = useHourlyVolumeByToken(LINK);
  const SOLVolume = useHourlyVolumeByToken(SOL);
  const MATICVolume = useHourlyVolumeByToken(MATIC);
  const AVAXVolume = useHourlyVolumeByToken(AVEX);
  const DOGEVolume = useHourlyVolumeByToken(DOGE);

  const formattedTokensInfo: FormattedTokenInfo[] = useMemo(() => {
    return [
      {
        name: "Bitcoin",
        symbol: "BTC",
        img: btcIcon,
        price: BTCData.price.toFixed(2),
        change: BTCData.percent_change_24h.toFixed(2) + "%",
        volume24h: formatVolume(BTCVolume.data.volume),
      },
      {
        name: "Ethereum",
        symbol: "ETH",
        img: ethIcon,
        price: ETHData.price.toFixed(2),
        change: ETHData.percent_change_24h.toFixed(2) + "%",
        volume24h: formatVolume(ETHVolume.data.volume),
      },
      {
        name: "BNB",
        symbol: "BNB",
        img: bnbIcon,
        price: BNBData.price.toFixed(2),
        change: BNBData.percent_change_24h.toFixed(2) + "%",
        volume24h: formatVolume(BNBVolume.data.volume),
      },
      {
        name: "XRP",
        symbol: "XRP",
        img: xrpIcon,
        price: XRPData.price.toFixed(2),
        change: XRPData.percent_change_24h.toFixed(2) + "%",
        volume24h: formatVolume(XRPVolume.data.volume),
      },
      {
        name: "Chainlink",
        symbol: "LINK",
        img: linkIcon,
        price: LINKData.price.toFixed(2),
        change: LINKData.percent_change_24h.toFixed(2) + "%",
        volume24h: formatVolume(LINKVolume.data.volume),
      },
      {
        name: "Solana",
        symbol: "SOL",
        img: solIcon,
        price: SOLData.price.toFixed(2),
        change: SOLData.percent_change_24h.toFixed(2) + "%",
        volume24h: formatVolume(SOLVolume.data.volume),
      },
      {
        name: "Polygon",
        symbol: "MATIC",
        img: maticIcon,
        price: MATICData.price.toFixed(2),
        change: MATICData.percent_change_24h.toFixed(2) + "%",
        volume24h: formatVolume(MATICVolume.data.volume),
      },
      {
        name: "Avalanche",
        symbol: "AVEX",
        img: avaxIcon,
        price: AVAXData.price.toFixed(2),
        change: AVAXData.percent_change_24h.toFixed(2) + "%",
        volume24h: formatVolume(AVAXVolume.data.volume),
      },
      {
        name: "Doge coin",
        symbol: "DOGE",
        img: dogeIcon,
        price: DOGEData.price.toFixed(2),
        change: DOGEData.percent_change_24h.toFixed(2) + "%",
        volume24h: formatVolume(DOGEVolume.data.volume),
      },
    ];
  }, [
    BNBData.percent_change_24h,
    BNBData.price,
    BNBVolume.data.volume,
    BTCData.percent_change_24h,
    BTCData.price,
    BTCVolume.data.volume,
    ETHData.percent_change_24h,
    ETHData.price,
    ETHVolume.data.volume,
    XRPData.percent_change_24h,
    XRPData.price,
    XRPVolume.data.volume,
    LINKData.percent_change_24h,
    LINKData.price,
    LINKVolume.data.volume,
    SOLData.percent_change_24h,
    SOLData.price,
    SOLVolume.data.volume,
    MATICData.percent_change_24h,
    MATICData.price,
    MATICVolume.data.volume,
    AVAXData.percent_change_24h,
    AVAXData.price,
    AVAXVolume.data.volume,
    DOGEData.percent_change_24h,
    DOGEData.price,
    DOGEVolume.data,
  ]);

  return (
    <MainContainer>
      <DescriptionContainer>
        <SectionHeader>
          Introducing Palmswap V2 <br /> Built with DEXcellence.
        </SectionHeader>
        <Description>
          Palmswap V2 redefines <strong>decentralized trading</strong>{" "}
          excellence, providing users with a professional and powerful platform
          that ensures <strong>swift order executions</strong> with{" "}
          <strong>negligible price swings.</strong> A superior trading
          experience awaits you.
        </Description>
      </DescriptionContainer>
      <CardsContainer>
        <CardContainer>
          {formattedTokensInfo.map((token) => (
            <TokenCard key={token.name}>
              <TokenCardRow>
                <TokenCardTop>
                  <TokenImg src={token.img} />
                  <TokenName>
                    <WhiteTextSmall>{token.name}</WhiteTextSmall>
                    <Description>{token.symbol}</Description>
                  </TokenName>
                </TokenCardTop>
                <TokenLink
                  href="https://synthetic.palmswap.org/"
                  target="_blank"
                >
                  Trade
                </TokenLink>
              </TokenCardRow>
              <TokenCardRow>
                <TokenPrice>{token.price}</TokenPrice>
                <TokenStatContainer>
                  <TokenStats>
                    <Description>CHG</Description>
                    <WhiteTextSmall>{token.change}</WhiteTextSmall>
                  </TokenStats>
                  <TokenStats>
                    <Description>VOL</Description>
                    <WhiteTextSmall>{token.volume24h}</WhiteTextSmall>
                  </TokenStats>
                </TokenStatContainer>
              </TokenCardRow>
            </TokenCard>
          ))}
        </CardContainer>
        <InfoCardContainer>
          <Card>
            <CardHead>
              <CardImg src={walletIcon} />
              <WhiteTextSmall>Self-custodial</WhiteTextSmall>
            </CardHead>
            <CardDesctiptionContainer>
              <Description>
                Keep control of your funds at all times. Just connect your
                personal wallet, trade limitlessly in a few clicks with
                unparalleled speed, and disconnect it anytime.
              </Description>
            </CardDesctiptionContainer>
          </Card>
          <Card>
            <CardHead>
              <CardImg src={lightningIcon} />
              <WhiteTextSmall>Lightning Fast</WhiteTextSmall>
            </CardHead>
            <CardDesctiptionContainer>
              <Description>
                Start trading in seconds. Palmswap V2 is tailor built for
                high-volume traders and scalpers to enjoy trading with speed,
                power, and a comprehensive CEX-like experience
              </Description>
            </CardDesctiptionContainer>
          </Card>
          <Card>
            <CardHead>
              <CardImg src={zeroIcon} />
              <WhiteTextSmall>Zero price impact</WhiteTextSmall>
            </CardHead>
            <CardDesctiptionContainer>
              <Description>
                Say goodbye to price slippages and frontrunning. Our real-time
                price feeds using on-chain Chainlink oracles provide unmatched
                efficiency in every trade.
              </Description>
            </CardDesctiptionContainer>
          </Card>
        </InfoCardContainer>
      </CardsContainer>
    </MainContainer>
  );
};

export default IntroducingPalmswapV2;
