import { useEffect, useRef } from "react";

import { StyledAweberForm } from "./styled";
import { FORM_TYPES } from "./interface";

function createEarlyAccessForm(
  d = document,
  s = "script",
  id = "aweber-wjs-ae59ydswj"
) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = "//forms.aweber.com/form/04/1144901904.js";
  fjs.parentNode.insertBefore(js, fjs);
  return null;
}

function createSubscribeForm(
  d = document,
  s = "script",
  id = "aweber-wjs-ok8m0h0py"
) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = "//forms.aweber.com/form/75/634478175.js";
  fjs.parentNode.insertBefore(js, fjs);
  return null;
}

const FORM_CLASSES = {
  [FORM_TYPES.EARLY_ACCESS]: "AW-Form-1144901904",
  [FORM_TYPES.SUBSCRIBE]: "AW-Form-634478175",
};
export function getParentForm(formType: FORM_TYPES) {
  return document.querySelector(`.${FORM_CLASSES[formType]} form`);
}

export const WeberForm = () => {
  const earlyAccessFormRef = useRef();
  useEffect(() => {
    createEarlyAccessForm();
  }, []);

  const subscribeFormRef = useRef();
  useEffect(() => {
    createSubscribeForm();
  }, []);

  return (
    <StyledAweberForm>
      <div
        ref={earlyAccessFormRef}
        className={FORM_CLASSES[FORM_TYPES.EARLY_ACCESS]}
      ></div>
      <div
        ref={subscribeFormRef}
        className={FORM_CLASSES[FORM_TYPES.SUBSCRIBE]}
      ></div>
    </StyledAweberForm>
  );
};

// setTimeout(() => {
//   const form = getParentForm(FORM_TYPES.EARLY_ACCESS)
//
//   const sendForm = (event) => {
//     event.preventDefault();
//
//     const myForm = event.target;
//     const formData = new FormData(myForm);
//
//     fetch('https://www.aweber.com/scripts/addlead.pl', {
//       method: 'POST',
//       mode: 'no-cors',
//       credentials: "include",
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded"
//       },
//       // @ts-ignore
//       body: new URLSearchParams(formData).toString(),
//     })
//   };
//
//   form.addEventListener("submit", sendForm);
//
//   return () => {
//     form.removeEventListener('submit', sendForm)
//   }
// }, 1000)

// setTimeout(() => {
//   const form = getParentForm(FORM_TYPES.SUBSCRIBE)
//
//   const sendForm = (event) => {
//     event.preventDefault();
//
//     const myForm = event.target;
//     const formData = new FormData(myForm);
//
//     fetch('https://www.aweber.com/scripts/addlead.pl', {
//       method: 'POST',
//       mode: 'no-cors',
//       credentials: "include",
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded"
//       },
//       // @ts-ignore
//       body: new URLSearchParams(formData).toString(),
//     })
//   };
//
//   form.addEventListener("submit", sendForm);
//
//   return () => {
//     form.removeEventListener('submit', sendForm)
//   }
// }, 1000)
