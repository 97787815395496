import { useState } from "react";
import {
  ExploreMenuItem,
  ExploreMenuItemDescription,
  ExploreMenuItemHeader,
  ExploreMenuItemHeaderContainer,
  ExploreMenuItemIcon,
  ExploreMenuItemsContainer,
  ExploreMenuItemTag,
  ExploreMenuItemTextContainer,
  LessIcon,
  LinkGroup,
  LinkGroupLinkContainer,
  LinkGroupName,
  LinkGroupNameContainer,
  LinkIcon,
  MoreIcon,
  StyledDescription,
  StyledMenuHeader,
  StyledMenuItem,
  StyledMenuText,
} from "./styled";

import v1 from "assets/exploreV1.svg";
import v2 from "assets/exploreV2.svg";
import tradingEvent from "assets/exploreTradingEvent.svg";
import refferal from "assets/exploreReferral.svg";
import staking from "assets/exploreStaking.svg";
import vesting from "assets/exploreVesting.svg";
import tradeToEarn from "assets/exploreTradeToEarn.svg";
import { Box } from "@mui/material";

const exploreMenu = [
  {
    title: "Trading",
    children: [
      {
        icon: v1,
        title: "Protocol v1",
        description:
          "Start trading on Palmswap and experience the future of DeFi trading.",
        tag: "Offline",
        route: "https://app.palmswap.org",
      },
      {
        icon: v2,
        title: "Protocol v2",
        description:
          "Start trading on Palmswap v2 and experience the next generation of DeFi.",
        tag: "Live",
        route: "https://synthetic.palmswap.org",
      },
    ],
  },
  {
    title: "Earn",
    children: [
      {
        icon: staking,
        title: "Staking",
        description: "Stake PALM or PLP to earn Trading fees  in USDT.",
        tag: "Live",
        route: "https://synthetic.palmswap.org/#/buy",
      },
      {
        icon: vesting,
        title: "Vesting",
        description: "Lock your staked tokens to get more rewards.",
        tag: "Coming Soon",
      },
      {
        icon: refferal,
        title: "Referral Program",
        description: "Invite friends and earn up to 20% of their trading fees",
        tag: "Live",
        route: "https://synthetic.palmswap.org/#/referral",
      },
      {
        icon: tradeToEarn,
        title: "Trade to Earn",
        description:
          "Earn trading points with every trade and convert them into PALM every day.",
        tag: "Live",
        route: "https://synthetic.palmswap.org/#/event",
      },
    ],
  },
  {
    title: "Events",
    children: [
      {
        icon: tradingEvent,
        title: "Trading Event",
        description:
          "Earn trading points with every trade and convert them into PALM every day.",
        tag: "Live",
        route: "https://synthetic.palmswap.org/#/event",
      },
    ],
  },

  {
    title: "Buy PALM",
    route:
      "https://pancakeswap.finance/swap?outputCurrency=0x29745314B4D294B7C77cDB411B8AAa95923aae38",
  },
  {
    title: "Add Liquidity",
    route:
      "https://synthetic.palmswap.org/#/buy",
  },
];

const ExploreMobileMenu = ({ nav }) => {
  const [open, setOpen] = useState(false);

  return (
    <LinkGroup active={open} inner>
      {nav.children ? (
        <LinkGroupNameContainer onClick={() => setOpen(!open)}>
          <LinkGroupName>{nav.title}</LinkGroupName>
          {!open && <MoreIcon />}
          {open && <LessIcon />}
        </LinkGroupNameContainer>
      ) : (
        <LinkGroupLinkContainer href={nav.route}>
          <LinkGroupName>{nav.title}</LinkGroupName>
          <LinkIcon />
        </LinkGroupLinkContainer>
      )}
      {open && (
        <ExploreMenuItemsContainer>
          {nav.children.map((c, i) => (
            <ExploreMenuItemsContainer>
              <ExploreMenuItem
                disabled={c.tag === "Coming Soon"}
                key={c.title + i}
                href={c.tag === "Coming Soon" ? null : c.route}
              >
                <ExploreMenuItemIcon src={c.icon} />
                <ExploreMenuItemTextContainer>
                  <ExploreMenuItemHeaderContainer>
                    <ExploreMenuItemHeader>{c.title}</ExploreMenuItemHeader>
                    {c.tag && <ExploreMenuItemTag>{c.tag}</ExploreMenuItemTag>}
                  </ExploreMenuItemHeaderContainer>
                  <ExploreMenuItemDescription>
                    {c.description}
                  </ExploreMenuItemDescription>
                </ExploreMenuItemTextContainer>
              </ExploreMenuItem>
            </ExploreMenuItemsContainer>
          ))}
        </ExploreMenuItemsContainer>
      )}
    </LinkGroup>
  );
};

const MobileMenu = ({ nav }) => {
  const [open, setOpen] = useState(false);

  return (
    <LinkGroup active={open}>
      {nav.children || nav.title === "Explore" ? (
        <LinkGroupNameContainer onClick={() => setOpen(!open)}>
          <LinkGroupName>{nav.title}</LinkGroupName>
          {!open && <MoreIcon />}
          {open && <LessIcon />}
        </LinkGroupNameContainer>
      ) : (
        <LinkGroupLinkContainer href={nav.route}>
          <LinkGroupName>{nav.title}</LinkGroupName>
          <LinkIcon />
        </LinkGroupLinkContainer>
      )}
      {open &&
        nav.title === "Explore" &&
        exploreMenu.map((menu) => <ExploreMobileMenu nav={menu} />)}
      {open &&
        nav.title !== "Explore" &&
        nav.children.map((nav: any, i: any) => (
          <StyledMenuItem
            key={i}
            href={nav.route}
            target={nav.target}
            rel={nav.target && "noopener"}
          >
            {nav.icon && (
              <Box
                component="img"
                src={nav.icon}
                alt="icon"
                style={{ width: 22, height: 22 }}
              />
            )}
            <StyledMenuText>
              <StyledMenuHeader>{nav.title}</StyledMenuHeader>
              <StyledDescription>{nav.description}</StyledDescription>
            </StyledMenuText>
          </StyledMenuItem>
        ))}
    </LinkGroup>
  );
};

export default MobileMenu;
