import { Box } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { NavItemProps } from "./interface";
import {
  StyledDescription,
  StyledLink,
  StyledLinkContainer,
  StyledMenuHeader,
  StyledMenuItem,
  StyledSubLinkContainer,
  StyledTitle,
  StyledMenuText,
  ExploreMenuContainer,
  ExploreMenuLeft,
  ExploreMenuRight,
  ExploreMenuHeader,
  ExploreMenuItemTextContainer,
  ExploreMenuItem,
  ExploreMenuItemIcon,
  ExploreMenuItemHeader,
  ExploreMenuItemDescription,
  ExploreMenuItemsContainer,
  ExploreMenuDivider,
  ExploreMenuItemHeaderContainer,
  ExploreMenuItemTag,
  MenuAnimationContainer,
  AnimationSubLinkContainer,
} from "./styled";
import trade from "assets/exploreTrade.svg";
import earn from "assets/exploreEarn.svg";
import event from "assets/exploreEvent.svg";
import v1 from "assets/exploreV1.svg";
import v2 from "assets/exploreV2.svg";
import palm from "assets/explorePALM.svg";
import tradingEvent from "assets/exploreTradingEvent.svg";
import refferal from "assets/exploreReferral.svg";
import staking from "assets/exploreStaking.svg";
import vesting from "assets/exploreVesting.svg";
import tradeToEarn from "assets/exploreTradeToEarn.svg";

const exploreMenu = {
  discover: {
    title: "Discover Palmswap",
    children: [
      {
        icon: trade,
        title: "Trading",
        description:
          "Start trading on Palmswap and experience the future of DeFi trading.",
        children: [
          {
            icon: v1,
            title: "Protocol v1",
            description:
              "Start trading on Palmswap and experience the future of DeFi trading.",
            tag: "Offline",
            route: "https://app.palmswap.org",
            target: "_blank",
          },
          {
            icon: v2,
            title: "Protocol v2",
            description:
              "Start trading on Palmswap v2 and experience the next generation of DeFi.",
            tag: "Live",
            route: "https://synthetic.palmswap.org",
          },
        ],
      },
      {
        icon: earn,
        title: "Earn",
        description: "Stake your tokens and earn more.",
        children: [
          {
            icon: staking,
            title: "Staking",
            description: "Stake PALM or PLP to earn Trading fees  in USDT.",
            tag: "Live",
            route: "https://synthetic.palmswap.org/#/earn",
          },
          {
            icon: vesting,
            title: "Vesting",
            description: "Lock your staked tokens to get more rewards.",
            tag: "Coming Soon",
          },
          {
            icon: refferal,
            title: "Referral Program",
            description:
              "Invite friends and earn up to 20% of their trading fees",
            tag: "Live",
            route: "https://synthetic.palmswap.org/#/referral",
          },
          {
            icon: tradeToEarn,
            title: "Trade to Earn",
            description:
              "Earn trading points with every trade and convert them into PALM every day.",
            tag: "Live",
            route: "https://synthetic.palmswap.org/#/event",
          },
        ],
      },
      {
        icon: event,
        title: "Events",
        description: "Experience a unique kind of our events.",
        children: [
          {
            icon: tradingEvent,
            title: "Trading Event",
            description:
              "Earn trading points with every trade and convert them into PALM every day.",
            tag: "Live",
            route: "https://synthetic.palmswap.org/#/event",
          },
        ],
      },
    ],
  },
  tokens: {
    title: "Tokens",
    children: [
      {
        icon: palm,
        title: "Buy PALM",
        description: "Buy PALM",
        route:
          "https://pancakeswap.finance/swap?outputCurrency=0x29745314B4D294B7C77cDB411B8AAa95923aae38",
      },
    ],
  },
};

export const ExploreMenu = () => {
  const [active, setActive] = useState(0);

  return (
    <MenuAnimationContainer className="explore">
      <ExploreMenuContainer>
        <ExploreMenuLeft>
          <ExploreMenuHeader>{exploreMenu.discover.title}</ExploreMenuHeader>
          <ExploreMenuItemsContainer>
            {exploreMenu.discover.children.map((menu, i) => (
              <ExploreMenuItem
                disabled={false}
                key={menu.title + i}
                onMouseOver={() => {
                  setActive(i);
                }}
              >
                <ExploreMenuItemIcon src={menu.icon} />
                <ExploreMenuItemTextContainer>
                  <ExploreMenuItemHeader>{menu.title}</ExploreMenuItemHeader>
                  <ExploreMenuItemDescription>
                    {menu.description}
                  </ExploreMenuItemDescription>
                </ExploreMenuItemTextContainer>
              </ExploreMenuItem>
            ))}
          </ExploreMenuItemsContainer>
          <ExploreMenuDivider />
          <ExploreMenuHeader>{exploreMenu.tokens.title}</ExploreMenuHeader>
          <ExploreMenuItemsContainer>
            {exploreMenu.tokens.children.map((menu, i) => (
              <ExploreMenuItem
                disabled={false}
                key={menu.title + i}
                href={menu.route}
              >
                <ExploreMenuItemIcon src={menu.icon} />
                <ExploreMenuItemTextContainer>
                  <ExploreMenuItemHeaderContainer>
                    <ExploreMenuItemHeader>{menu.title}</ExploreMenuItemHeader>
                  </ExploreMenuItemHeaderContainer>
                  <ExploreMenuItemDescription>
                    {menu.description}
                  </ExploreMenuItemDescription>
                </ExploreMenuItemTextContainer>
              </ExploreMenuItem>
            ))}
          </ExploreMenuItemsContainer>
        </ExploreMenuLeft>
        <ExploreMenuRight>
          <ExploreMenuHeader>
            {exploreMenu.discover.children[active].title}
          </ExploreMenuHeader>
          <ExploreMenuItemsContainer>
            {exploreMenu.discover.children[active].children.map((menu, i) => (
              <ExploreMenuItem
                disabled={menu.tag === "Coming Soon"}
                href={menu.tag === "Coming Soon" ? null : menu.route}
                key={menu.title + i}
              >
                <ExploreMenuItemIcon src={menu.icon} />
                <ExploreMenuItemTextContainer>
                  <ExploreMenuItemHeaderContainer>
                    <ExploreMenuItemHeader>{menu.title}</ExploreMenuItemHeader>
                    {menu.tag && (
                      <ExploreMenuItemTag>{menu.tag}</ExploreMenuItemTag>
                    )}
                  </ExploreMenuItemHeaderContainer>
                  <ExploreMenuItemDescription>
                    {menu.description}
                  </ExploreMenuItemDescription>
                </ExploreMenuItemTextContainer>
              </ExploreMenuItem>
            ))}
          </ExploreMenuItemsContainer>
        </ExploreMenuRight>
      </ExploreMenuContainer>
    </MenuAnimationContainer>
  );
};

const NavItem = ({ nav }: NavItemProps) => {
  const location = useLocation();
  const active = location.pathname === nav.route;

  return (
    <StyledLinkContainer>
      {nav.children || nav.title === "Explore" ? (
        <StyledLink active={active}>{nav.title}</StyledLink>
      ) : (
        <StyledLink active={active} href={nav.route} target={nav.target}>
          {nav.title}
        </StyledLink>
      )}
      {!!nav.children && nav.title !== "Explore" && (
        <AnimationSubLinkContainer className="links">
          <StyledSubLinkContainer>
            <StyledTitle>{nav.title}</StyledTitle>

            {nav.children.map((nav: any, i: any) => (
              <StyledMenuItem
                key={i}
                href={nav.route}
                target={nav.target}
                rel={nav.target && "noopener"}
              >
                {nav.icon && (
                  <Box
                    component="img"
                    src={nav.icon}
                    alt="icon"
                    style={{ width: 22, height: 22 }}
                  />
                )}
                <StyledMenuText>
                  <StyledMenuHeader>{nav.title}</StyledMenuHeader>
                  <StyledDescription>{nav.description}</StyledDescription>
                </StyledMenuText>
              </StyledMenuItem>
            ))}
          </StyledSubLinkContainer>
        </AnimationSubLinkContainer>
      )}
      {nav.title === "Explore" && <ExploreMenu />}
    </StyledLinkContainer>
  );
};

export default NavItem;
