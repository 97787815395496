import { SectionHeader } from "components/Common/styled";
import { Box, styled, Typography } from "@mui/material";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "150px 200px 150px 200px",
  maxWidth: 1550,
  marginLeft: "auto",
  marginRight: "auto",
  [theme.breakpoints.down("sm")]: {
    padding: "84px 16px 84px 16px",
  },
}));

export const Title = styled(SectionHeader)(({ theme }) => ({
  marginBottom: 8,
}));

export const Description = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: "#C7C6CC",
}));

export const TermsContainer = styled(Box)(({ theme }) => ({
  marginTop: 90,
  width: "100%",
  height: "100%",
  display: "flex",
  gap: 60,
  color: "#C7C6CC",
  [theme.breakpoints.down("sm")]: {
    marginTop: 24,
    flexDirection: "column",
    gap: 24,
  },
}));

export const Navs = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 84,
  display: "flex",
  flexDirection: "column",
  width: 320,
  minWidth: 320,
  gap: 10,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    position: "static",
  },
}));

export const NavItem = styled("a")(({ theme }) => ({
  display: "flex",
  gap: 10,
  color: "#C7C6CC",
  textDecoration: "none",
}));

export const NavLetter = styled(Box)(({ theme }) => ({
  fontSize: 16,
  fontWeight: "bold",
  textTransform: "uppercase",
  width: 20,
  minWidth: 20,
}));

export const NavTitle = styled(Box)(({ theme }) => ({
  fontSize: 16,
}));

export const Contents = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  color: "#C7C6CC",
  gap: 60,
  [theme.breakpoints.down("sm")]: {
    gap: 24,
  },
}));

export const ContentsTopSectionText = styled(Box)(({ theme }) => ({
  fontSize: 16,
}));

export const ContentsTopSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 24,
}));

export const ContentsTopSectionTitle = styled(Box)(({ theme }) => ({
  fontSize: 16,
  fontWeight: "bold",
  textTransform: "uppercase",
}));

export const TermsSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 60,
  [theme.breakpoints.down("sm")]: {
    gap: 24,
  },
}));

export const Term = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

export const TermTitle = styled(Box)(({ theme }) => ({
  fontSize: 24,
  fontWeight: "bold",
}));

export const TermParagraphs = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

export const TermTitleExtra = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "20px",
  marginBottom: "20px",
}));

export const TermParagraph = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 26,
  [theme.breakpoints.down("sm")]: {
    gap: 10,
  },
}));

export const TermSubParagraph = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 52,
  [theme.breakpoints.down("sm")]: {
    gap: 10,
  },
}));

export const TermParagraphsNumber = styled(Box)(({ theme }) => ({
  fontSize: 16,
  fontWeight: "bold",
  minWidth: 42,
  [theme.breakpoints.down("sm")]: {
    minWidth: "auto",
  },
}));

export const TermSubParagraphsNumber = styled(Box)(({ theme }) => ({
  fontSize: 16,
  fontWeight: "bold",
  minWidth: 42,
  [theme.breakpoints.down("sm")]: {
    minWidth: "auto",
  },
}));

export const TermParagraphsText = styled(Box)(({ theme }) => ({
  marginBottom: "20px",
  fontSize: 16,
  "& a": {
    color: colors.main,
  },
}));

export const TermSubParagraphsText = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "20px",
}));
