import React from "react"
import BottomSection from "./BottomSection"
import CenterSection from "./CenterSection"
import { CenterBottom, LiquidityPageContainer } from "./styled"
import TopSection from "./TopSection"

const LiquidityPage = () => {
  return (
    <LiquidityPageContainer>
     <TopSection /> 
     <CenterBottom>
        <CenterSection />
        <BottomSection/>
      </CenterBottom> 
    </LiquidityPageContainer>
  )
}

export default LiquidityPage