import React, { useEffect, useRef, useState } from "react";
import {
  Arrow,
  ButtonContainer,
  Dropdown,
  DropdownContainer,
  DropdownItem,
  DropdownItemHeader,
  DropdownItemHeaderImg,
  DropdownItemHeaderText,
  DropdownItemText,
  StyledButton,
} from "./styled";

import synthetic from "assets/syntheticLogo.svg";

const TradeNowButton = ({ styles }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <ButtonContainer ref={ref}>
      <StyledButton
        color="secondary"
        variant="contained"
        onClick={() => {
          setShow(!show);
        }}
        style={styles}
      >
        Launch App
      </StyledButton>
      {show && (
        <DropdownContainer>
          <Arrow />
          <Dropdown>
            <DropdownItem href="https://synthetic.palmswap.org" target="_blank">
              <DropdownItemHeader>
                <DropdownItemHeaderImg src={synthetic} />
                <DropdownItemHeaderText>Synthetic</DropdownItemHeaderText>
              </DropdownItemHeader>
              <DropdownItemText>Palmswap Synthetic Exchange</DropdownItemText>
            </DropdownItem>
          </Dropdown>
        </DropdownContainer>
      )}
    </ButtonContainer>
  );
};

export default TradeNowButton;
