import useSWR from "swr";
import {getGatewayApiUrl} from "./getGateWayApiUrl";

type TokenInfo = {
  price: number,
  lastRequestedAt: number,
  percent_change_24h: number,
  volume_24h: number,
}

export const useTokenInfo = (token) => {
  const {
    data: tokenPrice = {
      price: 0,
      percent_change_24h: 0,
      volume_24h: 0,
      lastRequestedAt: 0,
    } satisfies TokenInfo,
  } = useSWR<TokenInfo>(
    [`FetchTokenPrices:${token}`, token],
    ([, token]) =>
      fetch(`${getGatewayApiUrl()}/prices/${token}`)
        .then((res) => res.json())
        .then((data) => data),
    {
      refreshInterval: 5 * 60 * 1000,
    }
  );
  return tokenPrice;
};
